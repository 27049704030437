import React from "react";

const Bloglist = () => {
  const data = [
    {
      title: "Ultimate Guide to Email Sequences",
      desc: "by EmailMiners | # | Email Marketing The Ultimate Guide to Email Sequences Introduction to Email Sequences What Are Email Sequences? Email Sequences are a series of emails automatically sent to subscribers based on specific triggers or schedules. They play a pivotal role in nurturing leads, building...",
      img: "/img/blog.png",
    },
    {
      title: "Ultimate Guide to Email Sequences",
      desc: "by EmailMiners | # | Email Marketing The Ultimate Guide to Email Sequences Introduction to Email Sequences What Are Email Sequences? Email Sequences are a series of emails automatically sent to subscribers based on specific triggers or schedules. They play a pivotal role in nurturing leads, building...",
      img: "/img/blog.png",
    },
    {
      title: "Ultimate Guide to Email Sequences",
      desc: "by EmailMiners | # | Email Marketing The Ultimate Guide to Email Sequences Introduction to Email Sequences What Are Email Sequences? Email Sequences are a series of emails automatically sent to subscribers based on specific triggers or schedules. They play a pivotal role in nurturing leads, building...",
      img: "/img/blog.png",
    },
    {
      title: "Ultimate Guide to Email Sequences",
      desc: "by EmailMiners | # | Email Marketing The Ultimate Guide to Email Sequences Introduction to Email Sequences What Are Email Sequences? Email Sequences are a series of emails automatically sent to subscribers based on specific triggers or schedules. They play a pivotal role in nurturing leads, building...",
      img: "/img/blog.png",
    },
    {
      title: "Ultimate Guide to Email Sequences",
      desc: "by EmailMiners | # | Email Marketing The Ultimate Guide to Email Sequences Introduction to Email Sequences What Are Email Sequences? Email Sequences are a series of emails automatically sent to subscribers based on specific triggers or schedules. They play a pivotal role in nurturing leads, building...",
      img: "/img/blog.png",
    },
    {
      title: "Ultimate Guide to Email Sequences",
      desc: "by EmailMiners | # | Email Marketing The Ultimate Guide to Email Sequences Introduction to Email Sequences What Are Email Sequences? Email Sequences are a series of emails automatically sent to subscribers based on specific triggers or schedules. They play a pivotal role in nurturing leads, building...",
      img: "/img/blog.png",
    },
    
  ];

  return (
    <section className="w-full bg-[#F2F2FA] py-32">
      <div className="w-[80%] mx-auto flex flex-col md:flex-row flex-wrap gap-6 justify-between">
        {data.map((item) => (
          <div className="md:w-[30%] rounded-lg overflow-hidden bg-white">
            <img src={item.img} alt="blogImage" className="w-full" />
            <div className="px-4 py-3">
              <h4 className="my-2 text-lg">{item.title}</h4>
              <p className="text-base text-[#666]">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Bloglist;
