import React, { useState } from "react";
import { FaPlusCircle } from "react-icons/fa";

const Values = () => {
  const [acc, setAcc] = useState({
    item1: false,
    item2: false,
    item3: false,
  });

  return (
    <section className="w-full bg-gradient-to-b z-0 pb-20 from-white via-[#F2F2FA] to-[#F2F2FA] relative">
      <div className="w-[70%]  mx-auto mt-32 flex flex-col md:flex-row justify-between">
        <div className=" w-full md:w-4/6 mb-16 md:mb-0">
          <img src="/img/about/value.png" alt="value" className="w-full" />
        </div>
        <div className="w-full md:w-2/6">
          <h3 className="text-3xl text-[#980071] mb-5">Our Core Values</h3>
          <p className="mb-5">
            You can learn much about how a company will support your growth by
            understanding what they’re working toward.
          </p>
          <p>
            <span className="text-[#000080]">
              <strong>Here’s a snapshot of our core values:</strong>
            </span>
          </p>
          <div>
            <div
              onClick={() =>
                setAcc((prevState) => ({
                  ...prevState,
                  item1: !prevState.item1,
                }))
              }
              className="bg-white rounded-lg px-4 py-5 border-l-8 border-[#00d8bf] cursor-pointer mb-4"
            >
              <div className="flex items-center space-x-4">
                {" "}
                <h4 className="text-lg text-[#980071] px-3">
                  Help others win, and we win too
                </h4>{" "}
                <FaPlusCircle className="text-xl text-[#330779]" />{" "}
              </div>
              <div
                className={`border-l-4 border-blue-500 text-[#666] px-3 overflow-hidden ${
                  acc.item1 ? "max-h-[500px] my-4 " : "max-h-0"
                } transition-all duration-1000`}
              >
                <p className="text-sm leading-7">
                  We believe in the quote by{" "}
                  <strong>
                    <em>Robert Ingersoll</em>
                  </strong>
                  <span>&nbsp;— ‘</span>
                  <strong>
                    <em>We rise</em>
                  </strong>
                  <span>
                    <strong> by lifting others</strong>.’{" "}
                  </span>
                </p>
                <p className="text-sm leading-7">
                  <span>
                    It is the reason we help other businesses grow, which in
                    return gives us growth.&nbsp;
                  </span>
                </p>
              </div>
            </div>
            <div
              onClick={() =>
                setAcc((prevState) => ({
                  ...prevState,
                  item2: !prevState.item2,
                }))
              }
              className="bg-white rounded-lg px-4 py-5 border-l-8 border-[#fcb900] cursor-pointer mb-4"
            >
              <div className="flex items-center space-x-4">
                {" "}
                <h4 className="text-lg text-[#980071] px-3">
                  Help others win, and we win too
                </h4>{" "}
                <FaPlusCircle className="text-xl text-[#330779]" />{" "}
              </div>
              <div
                className={` text-[#666] px-3 overflow-hidden ${
                  acc.item2 ? "max-h-[500px] my-4 " : "max-h-0"
                } transition-all duration-1000`}
              >
                <p className="text-sm leading-7">
                For us, our most important stakeholder is not our stockholders; it is our customers. We’re in business to serve the needs and desires of our core customer base. We truly believe in ~ {" "}
                  <strong>
                    <em>John Mackey.</em>
                  </strong>
                  
                </p>
                <p className="text-sm leading-7">
                  <span>
                    It is the reason we help other businesses grow, which in
                    return gives us growth.&nbsp;
                  </span>
                </p>
              </div>
            </div>
            <div
              onClick={() =>
                setAcc((prevState) => ({
                  ...prevState,
                  item3: !prevState.item3,
                }))
              }
              className="bg-white rounded-lg px-4 py-5 border-l-8 border-[#1c58ef] cursor-pointer mb-4"
            >
              <div className="flex items-center space-x-4">
                {" "}
                <h4 className="text-lg text-[#980071] px-3">
                  Deliver services smartly and consistently
                </h4>{" "}
                <FaPlusCircle className="text-xl text-[#330779]" />{" "}
              </div>
              <div
                className={` text-[#666] px-3 overflow-hidden ${
                  acc.item3 ? "max-h-[500px] my-4 " : "max-h-0"
                } transition-all duration-1000`}
              >
                <p className="text-sm leading-7">
                  Despite all the hype and excitement about AI, it’s still
                  extremely limited today relative to what human intelligence
                  is.{" "}
                  <strong>
                    <em>Andrew Ng </em>
                  </strong>
                </p>
                <p className="text-sm leading-7">
                  <span>
                    We use AI, human intelligence, and a consistent approach
                    with a positive mindset to grow with you.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url('/img/bottom_white.svg')",
          backgroundSize: "100% 250px",
        }}
        className="h-[250px] z-[-1] absolute bottom-0 left-0 right-0"
      ></div>
    </section>
  );
};

export default Values;
