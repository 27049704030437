import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="bg-gradient-to-b from-[#330779] to-[#980071]">
      <div
        style={{ backgroundImage: `url("/img/home/hero.png")` }}
        className="bg-no-repeat bg-cover"
      >
        <div className="flex flex-col items-center justify-evenly  text-center gap-8 text-white pt-32 w-[80%] max-w-[1080px] mx-auto">
          <div>
            <h3 className="mb-5 md:tracking-widest uppercase">
              B2B Lead Generation Blog
            </h3>
            <h1 className="text-5xl md:text-7xl md:leading-[5rem] mb-5">
              Want to Learn About <br />
              Lead Generation?
            </h1>
          </div>
          <div className="mx-auto md:w-[50%]">
            <input
              type="email"
              placeholder="Email"
              className="py-3 px-4 w-full border-b-[3px] border-[#ffffff4d] bg-[#ffffff36] text-white focus:outline-none rounded-md"
            />
          <div className="py-2 px-7 mt-5 w-full bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <Link>
              <b>SUBSCRIBE</b>
            </Link>
          </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: "url('/img/Layer_gray.svg')",
            backgroundSize: "100% 250px",
          }}
          className="h-[250px] z-10 scale-x-[-1]"
        ></div>
      </div>
    </section>
  );
};

export default Hero;
