import React from "react";
import { Link } from "react-router-dom";

const Quality = () => {
  return (
    <section className="bg-gradient-to-b from-white via-[#F2F2FA] to-[#F2F2FA] w-full mt-32 z-10 relative">
      <div className=" flex flex-col md:flex-row w-[80%] mx-auto max-w[1080px] pb-20">
        <div className="w-full md:w-[60%] mb-16 md:mb-0 relative">
          <img src="/img/home/magnet.png" alt="second" />
        </div>
        <div className="w-full md:w-[40%]">
          <div>
            <h2 className="text-[#980071] md:text-[45px] leading-[1.2em] font-bold pb-5">
              A ‘Quality Over Quantity’ Mindset!
            </h2>
            <p className="mb-6">
              Our competitors would have you believe that their outdated,
              prebuilt lists are the most effective way to generate{" "}
              <span style={{ color: "#000080" }}>
                <strong>qualified sales leads</strong>
              </span>
              .
            </p>
            <p className="mb-6">
              At{" "}
              <span style={{ color: "#000080" }}>
                <strong>EmailMiners</strong>
              </span>
              , we disagree with this thinking. Here, we think a fresh, manually
              gathered{" "}
              <span style={{ color: "#000080" }}>
                <strong>targeted lead list</strong>
              </span>{" "}
              prepared with our hyper-targeting process is the only way to go.
              If you pick up the phone or send a LinkedIn message, we want it to
              be for a sales win rather than radio silence.
            </p>
          </div>
          <div>
            <div>
              <p className="mb-6">
                Our{" "}
                <span style={{ color: "#000080" }}>
                  <strong>sales prospecting strategies</strong>
                </span>{" "}
                have been developed over a decade, following training with top
                industry sales leaders like{" "}
                <span style={{ color: "#000080" }}>
                  <strong>
                    <a
                      href="https://www.linkedin.com/in/johnbarrows/"
                      style={{ color: "#000080" }}
                      tabindex="-1"
                    >
                      John Barrows
                    </a>
                  </strong>
                </span>
                ,{" "}
                <span style={{ color: "#000080" }}>
                  <strong>
                    <a
                      href="https://www.linkedin.com/in/josh-braun/"
                      style={{ color: "#000080" }}
                      tabindex="-1"
                    >
                      Josh Braun
                    </a>
                  </strong>
                </span>
                ,{" "}
                <span style={{ color: "#000080" }}>
                  <strong>
                    <a
                      href="https://www.linkedin.com/in/williamallred/"
                      style={{ color: "#000080" }}
                      tabindex="-1"
                    >
                      Will Allred
                    </a>
                  </strong>
                </span>
                , and{" "}
                <span style={{ color: "#000080" }}>
                  <a
                    href="https://www.linkedin.com/in/kddorsey3/"
                    style={{ color: "#000080" }}
                    tabindex="-1"
                  >
                    <strong>Kevin Dorsey.</strong>
                  </a>
                </span>
                &nbsp; We’ve used their proven thought leadership to build a
                hyper-personalized targeting method that simply delivers
                results.
              </p>
              <p className="mb-6">
                <strong>
                  <span style={{ color: "#000080" }}>Let us show you how.</span>
                </strong>
              </p>
            </div>
          </div>
          <div className="flex w-full justify-center">
          <Link to={"/contact-us"} className="py-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">Talk to specialist</b>
          </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url('/img/bottom_white.svg')",
          backgroundSize: "100%",
        }}
        className="h-[100px] sm:h-[150px] md:h-[250px] z-[-1] absolute bottom-0 left-0 right-0"
      ></div>
    </section>
  );
};

export default Quality;
