import React, { useEffect } from 'react'
import Hero from './Hero'
import LeadgenFooter from '../Footer/LeadgenFooter'
import Information from './Information'
import ContactFooter from '../Footer/ContactFooter'

const Contact = () => {
  useEffect(() => {
    document.title = "Contact Us - EmailMiners - Generate B2B Leads on Autopilot";  
  }, []);
  return (
    <>
        <Hero />
        <Information />
        <ContactFooter />
    </>
  )
}

export default Contact