import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="bg-gradient-to-b from-[#330779] to-[#980071]">
      <div
        style={{ backgroundImage: `url("/img/home/hero.png")` }}
        className="bg-no-repeat bg-cover"
      >
        <div className="flex flex-col items-center justify-evenly  text-center gap-8 text-white pt-32 w-[80%] max-w-[1080px] mx-auto">
          <div>
            <h3 className="mb-5 md:tracking-widest">
            Sniper-Targeted Prospecting
            </h3>
            <h1 className="text-5xl md:text-7xl md:leading-[5rem] mb-5">B2B Lead Generation</h1>
          </div>
          <div className="mx-auto md:w-[70%]" >
            <p className="text-xl mb-8">
              <span style={{fontWeight: "400"}}>
              
                <span style={{color: "#ffff99"}}>
                  <strong>Lead generation </strong>
                </span>
                is the sales funnel’s first and most crucial stage.
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{fontWeight: "400"}}>
              It is the process of finding prospects interested in doing business with you. In B2B terms, these prospects can range from small businesses to MNCs.
              </span>
            </p>
           
            <p className="text-xl mb-8">
              <span style={{fontWeight: "400"}}>
              If done wrong, it can waste the organization’s 
                <span style={{color: "#ffff99"}}>
                  <strong>Lead Generation Machine</strong>
                </span>
                ” that will generate{" "}
                <span style={{color: "#ffff99"}}>
                  <strong>Time, Revenue,</strong>
                </span>{" "}
                and {" "}
                <span style={{color: "#ffff99"}}>
                  <strong>Resources.</strong>
                </span>
              </span>
            </p>
          </div>
          <Link to={"/contact-us"} className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">Let's talk Strategy</b>
          </Link>
          
          
          
        </div>
        <div
          style={{
            backgroundImage: "url('/image.svg')",
            backgroundSize: "100%",
          }}
          className="h-[100px] sm:h-[150px] md:h-[250px] z-10 scale-x-[-1]"
        ></div>
      </div>
    </section>
  );
};

export default Hero;
