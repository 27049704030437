import React, { useState } from "react";
import {
  FaCalendarAlt,
  FaLinkedin,
  FaPlusCircle,
  FaRegImages,
  FaSearchPlus,
  FaUserFriends,
} from "react-icons/fa";
import { HiOutlineIdentification } from "react-icons/hi2";
import { IoIosMailOpen } from "react-icons/io";
import { RiPuzzle2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

const Explore = () => {
  const [acc, setAcc] = useState({});
  const [data, setData] = useState([
    {
      id: 1,
      open: false,
      icon: <RiPuzzle2Fill />,
      title: "B2B Lead Generation",
      desc: '<p><span style="font-weight: 400;">Our experienced team will initiate the <span style="color: #000080;"><strong>prospecting</strong></span> phase for you, saving you time and resources. When we’ve developed a list of <span style="color: #000080;"><strong>high-quality prospects</strong></span>, we’ll share them with you to close the deal.</span></p>',
      hdesc:
        "<p><span>At&nbsp;</span><strong>EmailMiners</strong><span>, we assure you that each lead you get from us has the potential to be your future customer. Our&nbsp;<strong>lead gen experts</strong>&nbsp;help you identify &amp; engage leads. We leave the rest to your sales team to convert interested leads into clients.</span></p>",
    },
    {
      id: 2,
      open: false,
      icon: <FaUserFriends />,
      title: "B2B Sales Prospecting",
      desc: '<p><span style="font-weight: 400;">With our strategic approach to <span style="color: #000080;"><strong>B2B</strong> <strong>sales prospecting</strong></span>, we’ll ensure your communications are reaching their mark. This assurance will help you connect with more people in less time.</span></p>',
      hdesc:
        "<p><span>Do you want to reach your&nbsp;</span><strong>ideal prospects inbox</strong><span>?<br><strong>Sales prospecting</strong>&nbsp;is the process of locating potential clientele for your products or services.&nbsp;</span><br><span>We help you meet&nbsp;</span><strong>FUTURE CLIENTS</strong><span>&nbsp;with our sniper-focused Sales prospecting strategy.&nbsp;</span></p>",
    },
    {
      id: 3,
      open: false,
      icon: <FaLinkedin />,
      title: "LinkedIn Lead Generation",
      desc: '<p style="text-align: center;"><span style="font-weight: 400;">Tap into the opportunity available through social media. </span><span style="font-weight: 400;">Let us lead you in developing a robust <span style="color: #000080;"><strong>LinkedIn lead generation strategy</strong></span> designed to <span style="color: #000080;"><strong>fill your sales pipeline</strong></span> with top-tier prospects.</span></p>',
      hdesc:
        "<p><span>Do you want to fill your calendar with ongoing leads? If yes, you are at the right place. At EmailMiners, we set up a&nbsp;</span><strong>“LinkedIn Lead Generation Machine”&nbsp;</strong><span>that fills your pipeline with&nbsp;</span><strong>qualified leads</strong><span>.</span></p>",
    },
    {
      id: 4,
      open: false,
      icon: <IoIosMailOpen />,
      title: "Cold Email Outreach",
      desc: '<p><span style="font-weight: 400;">Experience greater success with our proven and effective <span style="color: #000080;"><strong>cold email outreach campaign strategy</strong></span> that will lead to sales conversions and close more deals.&nbsp;</span></p>',
      hdesc:
        '<p><span><strong>Cold email outreach</strong> is the email campaign sent to potential buyers who have yet to have a prior relationship with you. </span><span data-preserver-spaces="true">We assist you with personalized&nbsp;</span><strong><span data-preserver-spaces="true">cold email outreach,</span></strong><span data-preserver-spaces="true">&nbsp;which helps you convert a stranger to an interested lead.&nbsp;</span></p>',
    },
    {
      id: 5,
      open: false,
      icon: <FaCalendarAlt />,
      title: "Appointment Setting",
      desc: '<p><span style="font-weight: 400;">Imagine your calendar filled with prospect calls, highly likely to convert. Let our expert team help <span style="color: #000080;"><strong>schedule appointments with qualified leads</strong></span> eager to learn more about your product or services.</span></p>',
      hdesc:
        "<p><strong>Appointment Setting</strong><span>&nbsp;is one of the most critical points in the sales funnel. In today’s competitive scenario, you need the most professional yet approachable style to stand out from the ever-growing crowd. We use <strong>human intelligence</strong> and a <strong>sniper-targeted</strong> approach to deliver&nbsp;<strong>qualified leads</strong>.</span></p>",
    },
    {
      id: 6,
      open: false,
      icon: <FaRegImages />,
      title: "Campaign Management",
      desc: '<p><span style="font-weight: 400;">Using our specialized campaign strategy and tools, we will help you craft a successful lead funnel to <span style="color: #000080;"><strong>generate valuable sales opportunities</strong></span> for your business.</span></p>',
      hdesc:
        "<p>Industry expert sales reps will do campaign Management. They will set up <strong>Lead Generation Funnel</strong> and monitor the campaign closely to get the desired result. The campaigns managed by a professional sales team will work on a vision to get the <strong>desired ROI</strong>.</p>",
    },
    {
      id: 7,
      open: false,
      icon: <FaSearchPlus />,
      title: "Qualified Leads",
      desc: '<p><span style="font-weight: 400;">Entrust your cold outreach and lead qualification tasks to us. We will provide your sales team with <span style="color: #000080;"><strong>warm sales leads</strong></span> ready to take the next step forward.</span></p>',
      hdesc:
        "<p><span>A<strong>&nbsp;qualified lead</strong>&nbsp;is a stage when the&nbsp;<strong>ideal prospect</strong>&nbsp;has shown interest in your services. We will drive the target audience from prospects to interested leads who want to learn more about the solutions or a meeting or demo. The leads will be handed over to your sales team for&nbsp;</span><strong>closing deals</strong><span>.</span></p>",
    },
    {
      id: 8,
      open: false,
      icon: <HiOutlineIdentification />,
      title: "B2B Company Database",
      desc: '<p><span>We work with you to do a critical job by identifying the likely accounts that are already seeking business solutions for your <span style="color: #000080;"><strong>future customers</strong></span>.</span></p>',
      hdesc:
        '<p><span>Identifying the likely account for future business is crucial. We’re the b2b&nbsp;</span><strong><a href="https://en.wikipedia.org/wiki/Lead_generation" target="_blank" rel="noopener noreferrer">lead generation</a>&nbsp;</strong><span>agency to get you the freshest and most accurate company database from your targeted marketplace. We are ready whether you need a 1000 or 10,0000&nbsp;</span><strong>b2b company database</strong><span>.</span></p>',
    },
  ]);

  const handleClick = (id) => {
    // Update the open state for the clicked item
    setData(
      data.map((item) =>
        item.id === id ? { ...item, open: !item.open } : item
      )
    );
  };

  return (
    <section className="w-full bg-[#F2F2FA] py-20">
      <div className="md:w-1/2 mx-auto text-center mb-10">
        <h2 className="md:text-5xl text-[#980071]">
          Explore Our B2B Lead Qualification Services
        </h2>
      </div>
      <div className="w-[80%] mx-auto flex flex-wrap justify-between space-y-5 mb-20">
        {data.map((item) => (
          <div className="bg-white rounded-md w-full md:w-[45%] max-h-fit shadow-[0px_2px_70px_0px] shadow-[#6E82D02E]">
            <div className="flex mb-5 px-4 py-5">
              <div className="text-4xl md:text-6xl text-[#330779]">
                {item.icon}
              </div>
              <div className="text-center md:p-3">
                <h3 className="text-[#330779] mb-5">{item.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: item.desc }} />
              </div>
            </div>
            <div
              onClick={() => handleClick(item.id)}
              className="bg-white  rounded-b-lg px-5 py-5 cursor-pointer shadow-[0px_2px_70px_0px] shadow-[#6e82d02e] "
            >
              <div className="flex items-center justify-between">
                {" "}
                <h4 className="text-lg text-[#980071] px-3">Learn More</h4>{" "}
                <FaPlusCircle className="text-xl text-[#330779]" />{" "}
              </div>
              <div
                className={` text-[#666] px-3 overflow-hidden ${
                  item.open ? "max-h-[500px] my-4 " : "max-h-0"
                } transition-all duration-1000`}
                dangerouslySetInnerHTML={{ __html: item.hdesc }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex w-full justify-center">
        <Link
          to={"/contact-us"}
          className="py-4 block mb-5 px-7 mx-auto text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]"
        >
          <b className="uppercase">Request a quote</b>
        </Link>
      </div>
    </section>
  );
};

export default Explore;
