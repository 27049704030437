import "./App.css";
import Home from "./Components/Landing/Home";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import HomePage from "./Components/HomePage/Home";
import About from "./Components/About/About";
import Services from "./Components/Services/Services";
import Leadgen from "./Components/LeadGen/Leadgen";
import Contact from "./Components/Contact/Contact";
import Blog from "./Components/Blog/Blog";
import { useEffect } from "react";
import Aos from "aos";
import TandC from "./Components/Docs/TandC";
import { Cookie } from "./Components/Docs/Cookie";
import GDRP from "./Components/Docs/GDRP";
import Disclaimer from "./Components/Docs/Disclaimer";
import ScrollToTop from "./Components/ScrolltoTop";
import Pricing from "./Components/Pricing/Pricing";
import Faq from "./Components/Faq/Faq";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const trackingId = 'G-2G4QBLZ6TM';

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(trackingId);

    // Send initial page load
    ReactGA.send({ hitType: 'pageview', page: location.pathname });

    // Send page views on route changes
    const unlisten = () => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    };

    return () => {
      unlisten();
    };
  }, [location]);
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-b2b-lead-generation" element={<HomePage />} />
        <Route path="/about-emailminers" element={<About />} />
        <Route path="/b2b-lead-generation-services" element={<Services />} />
        <Route path="/lead-generation" element={<Leadgen />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/emailminers-blog" element={<Blog />} />
        <Route path="/terms-and-conditions" element={<TandC/>} />
        <Route path="/cookie-policy" element={<Cookie/>} />
        <Route path="/gdpr-privacy-policy" element={<GDRP/>} />
        <Route path="/disclaimer" element={<Disclaimer/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route path="/faq" element={<Faq/>} />
      </Routes>
    </>
  );
}

export default App;
