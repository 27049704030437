import React from "react";
import { Link } from "react-router-dom";

const LeadQualification = () => {
  return (
    <section className="w-full mb-20">
      <div className="w-[80%] mx-auto flex flex-col md:flex-row space-y-10 md:space-x-10">
        <div className=" w-full md:w-4/6 md:-mt-32 z-20">
          <img
            src="/img/leadgen/puzzle.png"
            alt="lead-gen"
            className="w-full"
          />
        </div>
        <div className="w-full md:w-2/6">
          <h2 className="text-3xl md:text-5xl text-[#980071] mb-8">
            Qualified Lead Generation!
          </h2>
          <p className="mb-5">
            <span>
              <span className="text-[#000080]">
                <strong>Lead qualification </strong>
              </span>{" "}
              refers to determining which potential customers are most likely to
              make an actual purchase.{" "}
            </span>
          </p>
          <p className="mb-5">
            <span>
            It is the first and crucial step of the {" "}
              <span className="text-[#000080]">
                <strong>sales funnel.</strong>
              </span>{" "}
              That’s why every business is trying to crack it.
            </span>
          </p>
          <p className="mb-5">
            <span>
            However, they don’t understand that {" "}
              <span className="text-[#000080]">
                <strong>lead generation</strong>
              </span>{" "}
              is more than just a list of emails to send proposals.
            </span>
          </p>
          <div className="flex justify-center w-full">

          <Link to={"/contact-us"} className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">Request a Quote</b>
          </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeadQualification;
