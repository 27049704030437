import React from "react";
import { FaFunnelDollar, FaUserTie } from "react-icons/fa";
import { FaPeopleCarryBox } from "react-icons/fa6";

const Details = () => {
  return (
    <section className=" w-full mb-3">
        <div className="w-full shadow-[inset_0_0_18px_rgba(0,0,0,0.3)] rounded-t-[2550px] rounded-b-[550px] py-20">

      
      <div className="w-[80%] mx-auto max-w-[1080px] flex flex-col md:flex-row justify-between">
        <div className="w-full md:w-[30%] text-center flex flex-col items-center space-y-3">
          <FaFunnelDollar className="text-5xl mb-3 text-[#330779]" />
          <h4 className="text-[#980071]">Leads That Convert</h4>
          <p className="text-sm leading-6">
            <span>
              Unlike many of the “
              <span className="text-[#000080]">
                <strong>b2b lead generation agencies</strong>
              </span>
              ” out there, we don’t provide you with a pre-collected list of
              leads. We collect new data for each{" "}
              <strong>
                <span className="text-[#000080]">cold outreach campaign</span>
              </strong>{" "}
              and ensure that everything meets their requirements.
            </span>
          </p>
        </div>
        <div className="w-full md:w-[30%] text-center flex flex-col items-center space-y-3">
          <FaUserTie className="text-5xl mb-3 text-[#330779]" />
          <h4 className="text-[#980071]">Pro yet Affordable</h4>
          <p  className="text-sm leading-6">
            <span>
            Even though we have years of experience and the best tools, we’re still very {" "}
              <span className="text-[#000080]">
                <strong>affordable</strong>
              </span> {" "}
              compared to other agencies. Our primary focus is satisfying our clients so they return to us for even more.
            </span>
          </p>
        </div>
        <div className="w-full md:w-[30%] text-center flex flex-col items-center space-y-3">
          <FaPeopleCarryBox className="text-5xl mb-3 text-[#330779]" />
          <h4 className="text-[#980071]">Friendly Team</h4>
          <p  className="text-sm leading-6">
            <span>
            We understand the importance of a good attitude when it comes to business. That’s why you’ll find our whole team {" "}
              <span className="text-[#000080]">
                <strong>super-friendly</strong>
              </span>
              {" "}
              and helpful, so you leave with the satisfaction that your business will go up.
            </span>
          </p>
        </div>
      </div>
      </div>
    </section>
  );
};

export default Details;
