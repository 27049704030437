import React from 'react'
import { Link } from 'react-router-dom'

const Bottom = () => {
  return (
    <div className="w-full bg-white py-3   text-center text-[#330779] font-bold">
            <p>
            Copyright 2024 @EmailMiners Sales Automation Private Limited - All Rights Reserved &nbsp; &nbsp;
            <Link target='_blank' to={"/terms-and-conditions"}>Terms And Conditions</Link> &nbsp;/&nbsp;
            <Link target='_blank' to={"/gdpr-privacy-policy"}> GDPR Privacy Policy</Link> &nbsp;/&nbsp;
            <Link target='_blank' to={"/disclaimer"}>Disclaimer </Link> &nbsp;/&nbsp;
            <Link target='_blank' to={"/cookie-policy"}> Cookie Policy</Link> &nbsp;/&nbsp;
            </p>
      </div>
  )
}

export default Bottom