import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="bg-gradient-to-b from-[#330779] to-[#980071]">
      <div
        style={{ backgroundImage: `url("/img/home/hero.png")` }}
        className="bg-no-repeat bg-cover"
      >
        <div className="flex flex-col items-center justify-evenly min-h-screen text-center gap-8 text-white pt-20 w-[80%] max-w-[1080px] mx-auto bg-no-repeat bg-cover">
          <div>
            <h4 className="mb-5 tracking-widest">
              Grow Your Business With Qualified B2B Leads
            </h4>
            <h1 className="text-5xl md:text-7xl mb-5">
              Hyper-Personalized Targeting To Drive More Sales
            </h1>
          </div>
          <div>
            <p className="mb-5 text-lg">
              Every sales manager has a decision: pre-qualify prospects or cold
              call unqualified lists.
            </p>
            <p className="mb-5 text-lg">
              It’s no secret that one option is worth the effort, and one is
              not.
            </p>
            <p className="text-lg">
              Thankfully, this isn’t a decision you must make when working with{" "}
              <span style={{ color: "#ffff99" }}>
                <strong>EmailMiners</strong>
              </span>{" "}
              to grow your business using{" "}
              <span style={{ color: "#ffff99" }}>
                <strong>qualified B2B leads</strong>
              </span>
              .
            </p>
          </div>
          <Link to={"/b2b-lead-generation-services"} className="py-4 px-7 shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[8px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">SPARK MY SALES SUCCESS</b>
          </Link>
          <div>
            <p>With EmailMiners, your business will:</p>
            <p>
              ✔ Save essential time and resources spent on{" "}
              <strong>
                <span style={{ color: "#ffff99" }}>sales prospecting</span>
              </strong>
              <br />✔ Start with a list of warm{" "}
              <span style={{ color: "#ffff99" }}>
                <strong>pre-qualified leads</strong>
              </span>{" "}
              for your product or service
              <br />✔ Receive guidance and support from an{" "}
              <span style={{ color: "#ffff99" }}>
                <strong>experienced sales team</strong>
              </span>
              <br />✔{" "}
              <span style={{ color: "#ffff99" }}>
                <strong>Improve ROI</strong>
              </span>{" "}
              for your business with a higher close percentage
            </p>
          </div>
          <div>
            <p>
              You don’t have time to waste contacting unqualified prospects, so
              let us deliver you a<br />
              <span style={{ color: "#ffff99" }}>
                <strong>list of high-quality leads</strong>
              </span>{" "}
              ripe for sale.
            </p>
            <p>
              With{" "}
              <span style={{ color: "#ffff99" }}>
                <strong>hyper-personalized targeting strategies</strong>
              </span>
              , we can help you maximize the efficiency of your small sales team
              to grow your business and feel like a winner every day.
            </p>
          </div>
          <Link to={"/b2b-lead-generation-services"} className="py-4 px-7 shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[8px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">TELL ME MORE</b>
          </Link>
        </div>
        <div
          style={{
            backgroundImage: "url('/image.svg')",
            backgroundSize: "100% 250px",
          }}
          className="h-[250px] z-10 scale-x-[-1]"
        ></div>
      </div>
    </section>
  );
};

export default Hero;
