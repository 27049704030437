import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";

const Faq = () => {
  useEffect(() => {
    document.title = "EmailMiners FAQs - B2B Lead Generation Questions";
  }, []);
  const [faq, setFaq] = useState([
    {
      id: 1,
      open: false,
      question: "Do I have admin access?",
      ans: "Yes, you will have admin access for each workspace and ability to add/remove/modify domains or users. Also, you are sole-owner of the accounts and can choose to setup billing direct with provider in future; Email Service Providers will decide the pricing based on your region or billing details used.",
    },
    {
      id: 2,
      open: false,
      question: "How many accounts per domain are optimal?",
      ans: "To ensure a seamless outreach experience, each alternate domain will have its own dedicated workspace with three email accounts at max. This approach minimizes the risk of your entire outreach being compromised, allowing you to easily switch domains, workspaces, or emails if any issues arise. Note: These limits are not enforced by EmailMiners or Email Service provider but are rational choice for cold emailing.",
    },
    {
      id: 3,
      open: false,
      question: "How many emails can we send per email?",
      ans: "There are no limits defined by EmailMiners or Email Service Providers. However, optimal daily limits are; 10 New, 20 Follow-up, 20 Warmup with 90% reply rate.",
    },
    {
      id: 4,
      open: false,
      question: "What will be the location of the accounts?",
      ans: "Location for the licenses will be set as per your location used for billing or setup in the EmailMiners account. Note: ESPs control email routing and users/admins don’t have access to change or modify.",
    },
    {
      id: 5,
      open: false,
      question: "To which apps accounts can be connected to?",
      ans: "Accounts can be connected to any email app (Instantly, Smartleads, ReachInbox) that allows connection with Email service providers through oAuth, App Password or IMAP/POP.",
    },
    {
      id: 6,
      open: false,
      question: "Which domain registrar is supported?",
      ans: "Any domain registrars. Recommended registrars for cold emailing are GoDaddy & Cloudflare.",
    },
    {
      id: 7,
      open: false,
      question: "Can I move existing accounts to Reseller?",
      ans: "Yes you can, you will have to delist the domains from existing workspace & then resellers can issue licenses under their program. You will loose all data & need to rewarmup.",
    },
    {
      id: 8,
      open: false,
      question: "How does the billing work?",
      ans: "You will be charged on the 1st of each month for renewal of the accounts. For cancellation, you need to cancel prior to renewal date.",
    },
    {
      id: 9,
      open: false,
      question: "Why is the pricing $3 with resellers when direct pricing for the US is 7 USD or the UK at 8 GBP?",
      ans: "Resellers get volume based discount against a commit. Hence, you get the pricing benefit passed on to you.",
    },
    {
      id: 10,
      open: false,
      question: "How to verify if someone is a registered Google partner or they are simply a 3rd party OR using VPNs to get you your accounts?",
      ans: "Ask them to show Partner program portal over a live call & check for their verification to Sell & provide Technical support.",
    },
    {
      id: 11,
      open: false,
      question: "Will the reseller have access to my accounts?",
      ans: "No, when you work with a reseller you are required to update the password for each of your email accounts at the first login, this ensures resellers are locked out of your account. If the reseller is setting up your account on your behalf change all the passwords before starting email sends.",
    },
    {
      id: 12,
      open: false,
      question: "What if the reseller’s company runs out of business & closes?",
      ans: "Services provided to you are from Google & Microsoft, not the reseller. In case, any reseller shuts down their company you can add your own payment method to the accounts & switch to your region pricing.",
    },
  ]);
  const handleClick = (id) => {
    // Update the open state for the clicked item
    setFaq(
      faq.map((item) => (item.id === id ? { ...item, open: !item.open } : item))
    );
  };
  return (
    <>
      <section className="text-center ">
        <h2 className="text-3xl md:text-5xl text-[#333] my-16">
          Answers to your frequent{" "}
          <span className="text-[#980071]">questions</span>
        </h2>
        <div className="md:w-[60%] mx-auto text-left flex flex-col gap-y-4 mb-32">
          {faq.map((item) => (
            <div>
              <div
                onClick={() => handleClick(item.id)}
                className={`border ${
                  item.open
                    ? "border-purple-400 bg-gradient-to-b from-white to-[#EEE9FF]"
                    : "bg-[#F8F8F8]"
                } flex px-10 py-5 gap-x-5 cursor-pointer rounded-3xl text-xl justify-between items-center`}
              >
                <p> {item.question} </p>
                {item.open ? (
                  <CiSquareMinus className="text-3xl" />
                ) : (
                  <CiSquarePlus className="text-3xl" />
                )}
              </div>
              <div
                className={` text-[#666] px-3 overflow-hidden ${
                  item.open ? "max-h-[500px] my-4 " : "max-h-0"
                } transition-all duration-1000 text-left`}
              >
                {item.ans}
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Faq;
