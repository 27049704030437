import React from "react";
import { Link } from "react-router-dom";

const Video = () => {
  return (
    <section className="bg-gradient-to-b relative z-10 from-[#330779] to-[#980071] text-white pb-20 mt-20">
      <div className="flex justify-center w-[80%] m-auto max-w-[1080px] -top-20 h-fit relative pt-[45%] md:pt-[38.2963%] lg:pt-[31.2963%]">
        <iframe
          loading="lazy"
          title="EmailMiners.com - LinkedIn Lead Generation - B2B Lead Generation&nbsp;- Cold email outreach for Demo"
          src="https://www.youtube.com/embed/3if2ZTy0L1A?feature=oembed"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen=""
          name="fitvid0"
          className="w-full h-full absolute top-0 left-0 rounded-3xl"
        ></iframe>
      </div>
      <div className="flex flex-col md:flex-row gap-10 w-[70%] ml-[15%]">
        <div data-aos="fade-up" className="w-full md:w-2/3">
          <img src="/img/home/fourth.png" />
        </div>
        <div className="w-full md:w-1/3">
          <h2 className="text-[#fca497] text-4xl">Savings Beyond Compare</h2>
          <p className="mb-5">
            &nbsp;If your sales team is maxed out and looking for ways to
            ENERGIZE and GAIN EFFICIENCY in their process, then EmailMiners is
            the solution. Working with{" "}
            <span style={{ color: "#ffff99" }}>
              <strong>EmailMiners</strong>
            </span>{" "}
            will pay dividends for any small business by:
          </p>
          <ul className="list-disc mb-5">
            <li style={{ fontWeight: "400" }} aria-level="1">
              <span style={{ fontWeight: "400" }}>
                <span style={{ color: "#ffff99" }}>
                  <strong>SAVING</strong>
                </span>{" "}
                valuable time
              </span>
            </li>
            <li style={{ fontWeight: "400" }} aria-level="1">
              <span style={{ fontWeight: "400" }}>
                <span style={{ color: "#ffff99" }}>
                  <strong>GENERATING</strong>
                </span>{" "}
                additional revenue
              </span>
            </li>
            <li style={{ fontWeight: "400" }} aria-level="1">
              <span style={{ fontWeight: "400" }}>
                <span style={{ color: "#ffff99" }}>
                  <strong>SUPPORTING</strong>
                </span>{" "}
                sales resources
              </span>
            </li>
          </ul>
          <p className="mb-5">
            <span style={{ fontWeight: "400" }}>
              What value would you put in keeping your sales leaders happy,
              improving your sales conversion rates, and using hands-off down{" "}
              <span style={{ color: "#ffff99" }}>
                <strong>sales funnel campaigns</strong>
              </span>
              ? <br />
            </span>
          </p>
          <Link to={"/contact-us"} className="py-5 px-7 text-[0.5rem] md:text-sm text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">Talk to specialist</b>
          </Link>
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url('/image.svg')",
          backgroundSize: "100% ",
        }}
        className="h-[100px] sm:h-[150px] md:h-[480px] absolute bottom-0 z-[-1] scale-x-[1] left-0 right-0"
      ></div>
    </section>
  );
};

export default Video;
