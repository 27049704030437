import React, { useEffect } from "react";
import Hero from "./Hero";
import Bloglist from "./Bloglist";
import BlogFooter from "../Footer/BlogFooter";

const Blog = () => {
  useEffect(() => {
    document.title = "EmailMiners Blog - B2B Lead Generation Tips and Tricks";
  }, []);
  return (
    <>
      <Hero />
      <Bloglist />
      <BlogFooter />
    </>
  );
};

export default Blog;
