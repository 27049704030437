import React, { useEffect } from "react";
import Hero from "./Hero";
import LeadQualification from "./LeadQualification";
import HowWeDo from "./HowWeDo";
import Prospect from "./Prospect";
import Clients from "../Landing/Clients";
import LeadgenFooter from "../Footer/LeadgenFooter";

const Leadgen = () => {
  useEffect(() => {
    document.title = "Lead Generation - EmailMiners - B2B Lead Qualification";  
  }, []);
  return (
    <>
      <Hero />
      <LeadQualification />
      <HowWeDo />
      <Prospect />
      <Clients />
      <LeadgenFooter />
    </>
  );
};

export default Leadgen;
