import React from "react";
import { BsArrowsAngleContract } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";
import { FaAt, FaGlobeAmericas, FaUser } from "react-icons/fa";
import { HiUsers } from "react-icons/hi2";
import { Link } from "react-router-dom";

const HowWeDo = () => {
  const data = [
    {
      icon: <BsArrowsAngleContract />,
      title: "Defining Ideal Prospect",
      desc: '<p><span>Schedule a <span style="color: #000080;"><strong>quick discovery call</strong></span>. In the discovery call, we together will define your goals and objectives to overcome. The next step is to determine your <span style="color: #000080;"><strong>ICP (Ideal Customer Profile)</strong></span>.</span></p>',
    },
    {
      icon: <HiUsers />,
      title: "Research and Qualification",
      desc: '<p><span>Now that we know who you want to do business with, we identify them on <span style="color: #000080;"><strong>LinkedIn</strong></span> and other social media platforms. We qualify them according to the given <span style="color: #000080;"><strong>ideal customer profile</strong></span>.</span></p>',
    },
    {
      icon: <FaUser />,
      title: "Human Intelligence",
      desc: '<p><span>We use&nbsp;</span><span style="color: #000080;"><strong>human intelligence</strong></span><span> to qualify the stakeholders and decision-makers for the next steps. Finding the <span style="color: #000080;"><strong>right decision-maker</strong> </span>is essential for a successful outbound campaign.&nbsp;</span></p>',
    },
    {
      icon: <CgNotes />,
      title: "Business Information",
      desc: '<p><span>We find the&nbsp;</span><span style="color: #000080;"><strong>business emails</strong></span><span>&nbsp;and social handles for the <span style="color: #000080;"><strong>outbound campaigns</strong></span> using the <span style="color: #000080;"><strong>lead-gen tools</strong></span> and human intelligence. Each data set is manually reverified to ensure a high concentration of active and ideal information of the prospects.</span></p>',
    },
    {
      icon: <FaAt />,
      title: "Quality Analysis",
      desc: '<p>Now we have the business information of the prospects with whom you want to do business. Our team will <span style="color: #000080;"><strong>validate the details</strong></span> and format them accordingly for the outbound campaign.</p>',
    },
    {
      icon: <FaGlobeAmericas />,
      title: "Ready to Go",
      desc: '<p><span>We are ready to go; we will deliver the <span style="color: #000080;"><strong>b2b leads list</strong></span> to you.&nbsp; The submitted dataset is prepared and validated specifically for the <span style="color: #000080;"><strong>Outbound marketing campaign </strong></span>per your ideal customer profile.&nbsp;</span></p>',
    },
  ];

  return (
    <section className="w-full pb-32">
      <div className="w-full text-center">
        <h2 className="text-[#330779] md:text-5xl mb-16">
          Lead Generation – How We Do It.
        </h2>
      </div>
      <div className="w-[80%]  flex mx-auto justify-between mb-32 flex-wrap gap-10">
        {data.map((item) => (
          <div className="flex flex-col space-y-4 md:w-[30%] justify-between items-center text-center py-7 px-3 rounded-xl shadow-[0px_2px_70px_0px] shadow-[#6E82D02E]">
            <div className="p-5 text-4xl rounded-full text-[#330779] bg-[#f7828e]">
              {item.icon}
            </div>
            <h3 className="text-lg text-[#330779]"> {item.title} </h3>
            <div
              dangerouslySetInnerHTML={{ __html: item.desc }}
              className="text-sm leading-6 text-[#666]"
            />
          </div>
        ))}
      </div>
      <div className="text-center  w-full text-[#980071] mb-10">
        <h2 className="md:text-5xl">
          B2B Lead Generation that gives
          <br />
          Desired Clients.
        </h2>
      </div>
      <div className="w-[90%] md:w-1/3 flex flex-col items-center mx-auto text-center text-gray-500">
        <p className="mb-5">
          While leads are probably the most crucial step in making new clients,
          it’s the first step. <br />
          <span className="text-[#000080]">
            <strong>Our b2b qualified leads are convertible</strong>
          </span>
          . It will help focus your energies on converting these ideal leads
          into actual clients by making your business as efficient as possible.
        </p>
        <p className="mb-10">
          To get the best of your leads, use them brilliantly and innovatively.
        </p>
        <div className="flex w-full justify-center">

        <Link to={"/contact-us"} className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">Talk to specialist</b>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HowWeDo;
