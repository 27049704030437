import React from "react";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

const Leaders = () => {
  const data = [
    {
      name: "Vish",
      desc: '<p>Vish is a passionate advocate for the small business owner, inspired to grow. As our leader, Vish ensures we focus on clients first, consistently <span style="color: #000080;"><strong>delivering high-quality results</strong></span>.</p>',
      designation: "Founder & CEO",
      facebook: "#",
      twitter: "#",
      linkedin: "https://www.linkedin.com/company/emailminers/%20",
    },
    {
      name: "Ravin",
      desc: '<p>Ravin is excited to share the results <span style="color: #000080;"><strong>EmailMiners</strong></span> has delivered and loves to talk with future clients. As our sales and business development leader, Ravin always seeks ways EmailMiners can contribute to growth and success.</p>',
      designation: "Head of Sales & Business",
      facebook: "#",
      twitter: "#",
      linkedin: "https://www.linkedin.com/company/emailminers/%20",
    },
    {
      name: "Sagar",
      desc: '<p>Sagar is a skilled <span style="color: #000080;"><strong>lead generation expert</strong></span> and assists EmailMiners in exploring new business opportunities. We are delighted to have Sagar aboard to help grow our business.</p>',
      designation: "Business Manager",
      facebook: "#",
      twitter: "#",
      linkedin: "https://www.linkedin.com/company/emailminers/%20",
    },
    {
      name: "Ranjeet",
      desc: '<p>It takes a lot of human power to grow a global business, and finding that talent is quite a job. But Ranjeet does a fantastic job delivering<span style="color: #000080;"><strong> top industry talent</strong></span> capable of helping our clients achieve their goals.</p>',
      designation: "Head of HR",
      facebook: "#",
      twitter: "#",
      linkedin: "https://www.linkedin.com/company/emailminers/%20",
    },
  ];

  return (
    <section className="w-full ">
      <div className="w-full text-center mb-10">
        <h3 className="md:text-[30px] text-[#330779]">Connect With Our Leaders</h3>
      </div>
      <div className="w-[80%]  flex flex-col sm:flex-row mx-auto gap-4 justify-between mb-10 ">
        {data.map((item) => (
          <div className="flex flex-col space-y-4 w-full sm:w-[45%] md:w-[23%] justify-between items-center text-center shadow-[0px_2px_70px_0px] shadow-[#6E82D02E] p-5 rounded-lg">
            <h3>{item.name} </h3>
            <div>
              <p className="text-gray-400"> {item.designation} </p>
              <div
                dangerouslySetInnerHTML={{ __html: item.desc }}
                className="text-sm leading-6 text-[#666]"
              />
            </div>
            <div className="flex space-x-3 text-[#f88590] text-xl">
              <Link to={item.facebook}>
                {" "}
                <FaFacebookF />{" "}
              </Link>
              <Link to={item.twitter}>
                {" "}
                <BsTwitterX />{" "}
              </Link>
              <Link to={item.linkedin}>
                {" "}
                <FaLinkedinIn />{" "}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Leaders;
