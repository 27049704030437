import React, { useState } from "react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";

const Billing = () => {
  const packages = [
    "Admin Access",
    "100% Ownership",
    "User Creation",
    "Profile Picture",
    "MX Setup",
    "SPF Setup",
    "DMARC Policy",
    "DKIM Authentication",
    "Domain Authentication",
    "Domain Forwarding",
    "Add to Email Tool",
    "Activate Warmup",
  ];
  const [faq, setFaq] = useState([
    {
      id: 1,
      open: false,
      question: "Do I have admin access?",
      ans: "Yes, you will have admin access for each workspace and ability to add/remove/modify domains or users. Also, you are sole-owner of the accounts and can choose to setup billing direct with provider in future; Email Service Providers will decide the pricing based on your region or billing details used.",
    },
    {
      id: 2,
      open: false,
      question: "How many accounts per domain are optimal?",
      ans: "To ensure a seamless outreach experience, each alternate domain will have its own dedicated workspace with three email accounts at max. This approach minimizes the risk of your entire outreach being compromised, allowing you to easily switch domains, workspaces, or emails if any issues arise. Note: These limits are not enforced by EmailMiners or Email Service provider but are rational choice for cold emailing.",
    },
    {
      id: 3,
      open: false,
      question: "How many emails can we send per email?",
      ans: "There are no limits defined by EmailMiners or Email Service Providers. However, optimal daily limits are; 10 New, 20 Follow-up, 20 Warmup with 90% reply rate.",
    },
    {
      id: 4,
      open: false,
      question: "What will be the location of the accounts?",
      ans: "Location for the licenses will be set as per your location used for billing or setup in the EmailMiners account. Note: ESPs control email routing and users/admins don’t have access to change or modify.",
    },
    {
      id: 5,
      open: false,
      question: "To which apps accounts can be connected to?",
      ans: "Accounts can be connected to any email app (Instantly, Smartleads, ReachInbox) that allows connection with Email service providers through oAuth, App Password or IMAP/POP.",
    },
    {
      id: 6,
      open: false,
      question: "Which domain registrar is supported?",
      ans: "Any domain registrars. Recommended registrars for cold emailing are GoDaddy & Cloudflare.",
    },
  ]);
  const handleClick = (id) => {
    // Update the open state for the clicked item
    setFaq(
      faq.map((item) => (item.id === id ? { ...item, open: !item.open } : item))
    );
  };
  return (
    <section className="w-full mb-20">
      <div className="w-[80%] mx-auto flex flex-col space-y-10 items-center text-center">
        <div className="w-full mt-16">
          <h2 className="text-3xl md:text-5xl text-[#333] mb-16 md:mb-24">
            Monthly billing, easy{" "}
            <span className="text-[#980071]">to upgrade or downgrade!</span>
          </h2>
          <div className="flex-col gap-y-4 flex justify-center md:flex-row md:w-[80%] md:space-x-8  mx-auto text-left mb-20">
            
            <div className="border shadow-md px-6 py-5 md:w-1/2 cursor-pointer rounded-3xl hover:border-purple-400 hover:bg-gradient-to-b from-white via-white to-purple-100">
              <div className="bg-white py-10 px-10">
                <img src="/img/workspace.svg" />
              </div>
              <h4 className="text-3xl text-blue-600 mb-5">
              Google Workspace{" "}
              </h4>
              <div className="flex mb-5">
                <h2 className="text-6xl">$3/</h2>
                <h2 className="text-2xl leading-7">
                  per <br /> month
                </h2>
              </div>
              <ul className="text-[#666] list-disc px-3 text-xl mb-10">
                <li>100% Free setup</li>
                <li>Billed monthly</li>
                <li>Cancel Anytime</li>
                <li>Minimum order 10 accounts</li>
                <li>All features of Google Business starter</li>
                <li>After sales support</li>
              </ul>
              <div className="flex justify-center w-full">
                <Link
                  to={"/contact-us"}
                  className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]"
                >
                  <b className="uppercase">Get Started</b>
                </Link>
              </div>
            </div>
          </div>
          <h2 className="text-3xl md:text-5xl text-[#333] mb-14">
            All packages <span className="text-[#980071]">include</span>
          </h2>
          <div className="flex gap-y-6 flex-wrap md:w-[90%] mx-auto text-xl text-[#666] mb-16">
            {packages.map((item) => (
              <div className="flex gap-2 w-full md:w-1/2 lg:w-[25%]  items-center">
                <IoMdCheckmarkCircle className="text-blue-600" />
                {item}
              </div>
            ))}
          </div>

          <h2 className="text-3xl md:text-5xl text-[#333] my-16">
            Answers to your frequent{" "}
            <span className="text-[#980071]">questions</span>
          </h2>
          <div className="md:w-[80%] mx-auto flex flex-col gap-y-4">
            {faq.map((item) => (
              <div>
                <div
                  onClick={() => handleClick(item.id)}
                  className={`border ${
                    item.open
                      ? "border-purple-400 bg-gradient-to-b from-white to-[#EEE9FF]"
                      : "bg-[#F8F8F8]"
                  } flex px-10 py-5 cursor-pointer rounded-3xl text-xl justify-between items-center`}
                >
                  <p> {item.question} </p>
                  {item.open ? (
                    <CiSquareMinus className="text-3xl" />
                  ) : (
                    <CiSquarePlus className="text-3xl" />
                  )}
                </div>
                <div
                  className={` text-[#666] px-3 overflow-hidden ${
                    item.open ? "max-h-[500px] my-4 " : "max-h-0"
                  } transition-all duration-1000 text-left`}
                >
                  {item.ans}
                </div>
              </div>
            ))}
          </div>
          <p className="text-2xl my-10">Explore All <Link to={"/faq"} className="text-blue-600">FAQs</Link></p>
        </div>
      </div>
    </section>
  );
};

export default Billing;
