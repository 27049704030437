import React, { useEffect } from "react";
import Hero from "./Hero";
import Billing from "./Billing";
import Footer from "../Footer/Footer";

const Pricing = () => {
  useEffect(() => {
    document.title = "EmailMiners Pricing - B2B Lead Generation Pricing";
  }, []);
  return (
    <>
      <Hero />
      <Billing/>
      <Footer/>
    </>
  );
};

export default Pricing;
