import React, { useState } from "react";
import { BiLogoGmail } from "react-icons/bi";
import { FaFacebookF, FaLinkedinIn, FaPinterestP } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="w-full  sticky z-50 shadow-sm top-0 ">
      <div className="w-full  bg-[#2EA3F2] flex text-white py-1 text-xs items-center">
        <Link
          to={"mailto:bonjour@emailminers.com"}
          className="flex w-[80%] m-auto max-w-[1080px] items-center space-x-2"
        >
          <IoIosMail /> &nbsp; bonjour@emailminers.com{" "}
        </Link>
        <div></div>
      </div>
      <div className="w-full bg-white">
        <div className="w-[80%] bg-white m-auto max-w-[1080px] flex justify-between items-center">
          <Link to={"/"} className="w-[40%] md:w-[25%] py-2 md:py-0">
            <img
              src="/EmailMiners_logo.png"
              alt="logo"
              className="w-full h-full"
            />
          </Link>
          <div className="hidden md:flex space-x-5 text-[#00000088] font-semibold">
            <Link
              to={"/home-b2b-lead-generation"}
              className="hover:opacity-40 transition-all duration-300 ease-in-out"
            >
              Home
            </Link>
            <Link
              to={"/about-emailminers"}
              className="hover:opacity-40 transition-all duration-300 ease-in-out"
            >
              About us
            </Link>
            <Link
              to={"/b2b-lead-generation-services"}
              className="hover:opacity-40 transition-all duration-300 ease-in-out"
            >
              Services
            </Link>
            <Link
              to={"/lead-generation"}
              className="hover:opacity-40 transition-all duration-300 ease-in-out"
            >
              B2B Lead Generation
            </Link>
            <Link
              to={"/pricing"}
              className="hover:opacity-40 transition-all duration-300 ease-in-out"
            >
            Pricing
            </Link>
            <Link
              to={"/contact-us"}
              className="hover:opacity-40 transition-all duration-300 ease-in-out"
            >
              Contact Us
            </Link>
            <Link
              to={"/emailminers-blog"}
              className="hover:opacity-40 transition-all duration-300 ease-in-out"
            >
              Blog
            </Link>
            <Link
              to={"/faq"}
              className="hover:opacity-40 transition-all duration-300 ease-in-out"
            >
              FAQs
            </Link>
          </div>
          <div onClick={() => setOpen(!open)} className="block md:hidden">
            <RxHamburgerMenu className="text-blue-500 text-2xl" />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col gap-4 w-[70%] mx-[15%]  fixed overflow-hidden bg-white ${
          open ? "max-h-[300px] p-5" : "max-h-0 p-0"
        } transition-all duration-1000`}
      >
        <Link
          onClick={() => setOpen(!open)}
          to={"/home-b2b-lead-generation"}
          className="hover:opacity-40 transition-all duration-300 ease-in-out"
        >
          Home
        </Link>
        <Link
          onClick={() => setOpen(!open)}
          to={"/about-emailminers"}
          className="hover:opacity-40 transition-all duration-300 ease-in-out"
        >
          About us
        </Link>
        <Link
          onClick={() => setOpen(!open)}
          to={"/b2b-lead-generation-services"}
          className="hover:opacity-40 transition-all duration-300 ease-in-out"
        >
          Services
        </Link>
        <Link
          onClick={() => setOpen(!open)}
          to={"/lead-generation"}
          className="hover:opacity-40 transition-all duration-300 ease-in-out"
        >
          B2B Lead Generation
        </Link>
        <Link
          onClick={() => setOpen(!open)}
          to={"/pricing"}
          className="hover:opacity-40 transition-all duration-300 ease-in-out"
        >
          Pricing
        </Link>
        <Link
          onClick={() => setOpen(!open)}
          to={"/contact-us"}
          className="hover:opacity-40 transition-all duration-300 ease-in-out"
        >
          Contact Us
        </Link>
        <Link
          onClick={() => setOpen(!open)}
          to={"/emailminers-blog"}
          className="hover:opacity-40 transition-all duration-300 ease-in-out"
        >
          Blog
        </Link>
        <Link
          onClick={() => setOpen(!open)}
          to={"/faq"}
          className="hover:opacity-40 transition-all duration-300 ease-in-out"
        >
          FAQs
        </Link>
      </div>
      <div className="fixed hidden md:flex left-0 top-[30vh]  flex-col">
        <Link target="_blank" to={"http://www.facebook.com/sharer.php?u=https%3A%2F%2Femailminers.com%2Fabout-emailminers%2F&t=EmailMiners%20%E2%80%93%20About"} className="bg-blue-700 w-fit py-3 px-4 hover:px-2 hover:border-l-4 border-blue-950 transition-all duration-200">
          <FaFacebookF className="text-xl text-white " />
        </Link>
        <Link target="_blank" to={"http://twitter.com/share?text=EmailMiners%20%E2%80%93%20About&url=https%3A%2F%2Femailminers.com%2Fabout-emailminers%2F&via=@email_miners"} className="bg-blue-400 w-fit py-3 px-4 hover:px-2 hover:border-l-4 border-blue-950 transition-all duration-200">
          <FaXTwitter  className="text-xl text-white " />
        </Link>
        <Link target="_blank" className="bg-red-700 w-fit py-3 px-4 hover:px-2 hover:border-l-4 border-red-950 transition-all duration-200">
          <FaPinterestP className="text-xl text-white " />
        </Link>
        <Link target="_blank" to={"https://mail.google.com/mail/u/0/?view=cm&fs=1&su=EmailMiners%20%E2%80%93%20About&body=https%3A%2F%2Femailminers.com%2Fabout-emailminers%2F&ui=2&tf=1"} className="bg-red-500 py-3 w-fit px-4 hover:px-2 hover:border-l-4 border-red-950 transition-all duration-200">
          <BiLogoGmail className="text-xl text-white " />
        </Link>
        <Link target="_blank" to={"http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Femailminers.com%2Fabout-emailminers%2F&title=EmailMiners%20%E2%80%93%20About"} className="bg-blue-900 w-fit py-3 px-4 hover:px-2 hover:border-l-4 border-blue-950 transition-all duration-200">
          <FaLinkedinIn className="text-xl text-white " />
        </Link>
        
      </div>
    </nav>
  );
};

export default Navbar;
