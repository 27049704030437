import React from 'react'
import Bottom from '../Footer/Bottom'

const Disclaimer = () => {
const data = `<div class="et_pb_text_inner"><p><!-- divi:heading {"level":1} --></p>
<h1><span data-preserver-spaces="true">Disclaimer for <strong>EmailMiners Sales Automation Private Limited</strong></span></h1>
<ul></ul>
<p>&nbsp;</p>
<p><strong>Disclaimer</strong></p>
<p>This Disclaimer forms part of and must be read in conjunction with website Terms and Conditions. We reserve the right to change this Disclaimer at any time.</p>
<p>The information contained in this website www.emailminers.com or provided by us, is for general information purposes only. Here, <strong><span style="color: #000080;"><a href="https://emailminers.com/" style="color: #000080;">EmailMiners Sales Automation Private Limited</a></span></strong> refers to as (“we,” “us,” or “our”).</p>
<p>You understand and agree that we (a) do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us or any third parties; and (b) shall not be responsible for any materials posted by us or any third party. You shall use your judgment, caution, and common sense in evaluating any prospective methods or offers and any information provided by us or any third party.</p>
<p>Further, we shall not be liable for direct, indirect, consequential, or any other form of loss or damage that a user may suffer through the use of the www.emailminers.com Website, including loss of data or information or any financial or physical loss or damage.</p>
<p><strong>General:</strong><br>The website, its content, and service are provided on an “as is” and “as available” basis without any warranties of any kind, including that the website will operate error‐free or that the website, its servers, its content, or its service are free of computer viruses or similar contamination or destructive features. Although Emailminers.com seeks to maintain safe, secure, accurate, and well‐functioning services, we cannot guarantee the continuous operation of or access to our services. There may at times be inadvertent technical or factual errors or inaccuracies.</p>
<p><strong>A) No warranties.</strong><br>Emailminers.com specifically (but without limitation) disclaims<br>a. Any implied warranties of merchantability, fitness for a particular purpose, quiet enjoyment, or non‐infringement; and<br>b. Any warranties arising out of course‐of‐dealing, usage, or trade. You assume all risk for any/all damages that may result from your use of or access to the services. Emailminers.com shall not be responsible for the loss of, damage to, or unavailability of any information you have made available through the services, and you are solely responsible for ensuring that you have backup copies of any information you have made available through the services.</p>
<p><strong>B) No guarantee of accuracy.</strong><br><span style="color: #000080;"><strong>Emailminers.com</strong></span> does not guarantee the accuracy of and disclaims all liability for any errors or other inaccuracies in the information, content, recommendations, and materials made available through the services.</p>
<p>C) <strong>No warranties regarding third parties. <br><span style="color: #000080;">Emailminers.com</span></strong> makes no representations, warranties, or guarantees, express or implied, regarding any third-party service or advice provided by a third party.</p>
<p><strong>Technical Disclaimer</strong></p>
<p>Every effort is made to keep the website up and running smoothly. However, <span style="color: #000080;"><strong>Emailminers.com</strong></span> takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
<p>If you require any more information or have any questions about our site’s disclaimer, please feel free to contact us by email at <a href="mailto:bonjour@emailminers.com">bonjour@emailminers.com</a>.</p>
<p><!-- /divi:paragraph --></p></div>`

  return (
    <div className='w-full '>
    <div
        style={{
          backgroundImage: "url('/img/cloudtop.svg')",
          backgroundSize: "100%",
        }}
        className="h-[100px] sm:h-[100px] md:h-[100px] z-10 bg-no-repeat"
      />
    <div dangerouslySetInnerHTML={{__html:data}} className='w-[80%] mx-auto my-5'/>
    <div
        style={{
          backgroundImage: "url('/img/cloudbottom.svg')",
          backgroundSize: "100%",
        }}
        className="h-[100px] sm:h-[100px] md:h-[100px] z-10 bg-no-repeat"
      />
    <Bottom />
</div>
  )
}

export default Disclaimer