import React from "react";
import { AiFillChrome } from "react-icons/ai";
import { FaCalendarAlt, FaLinkedinIn, FaRegImages, FaSearchPlus, FaUserFriends } from "react-icons/fa";
import { HiOutlineIdentification } from "react-icons/hi";
import { IoIosMailOpen } from "react-icons/io";
import { RiPuzzle2Fill } from "react-icons/ri";

const Partner = () => {
  const data = [
    {
      icon:<RiPuzzle2Fill />,
      title: "B2B Lead Generation",
      desc: '<p>Let our lead gen specialists start the <strong><span style="color: #000080;">sales prospecting</span></strong> process for you, saving you time and valuable resources. Once we have a <span style="color: #000080;"><strong>lead list</strong></span>, we’re confident, your team can relish the taste of sweet sales success.</p>',
    },
    {
      icon:<FaUserFriends />,
      title: "B2B Sales Prospecting",
      desc: '<p>When we apply our <span style="color: #000080;"><strong>sales prospecting strategy</strong></span>, ensure your sales messages are landing in your ideal prospects inboxes. The result? You’ll reach more future clients in fewer tries.</p>',
    },
    {
      icon:<FaLinkedinIn />,
      title: "LinkedIn Lead Generation",
      desc: '<p>Social media is a vast landscape of opportunity, but finding that opportunity takes some strategic thought. Let us build a <span style="color: #000080;"><strong>LinkedIn Lead Generation Machine</strong></span> to fill your pipeline with high-quality prospects.</p>',
    },
    {
      icon:<IoIosMailOpen />,
      title: "Cold Email Outreach",
      desc: '<p>Reaching out to strangers via <span style="color: #000080;"><strong>cold email</strong> </span>may seem like a low-percentage opportunity, but we’ve repeatedly proven it’s worth the effort. Let us show you a <span style="color: #000080;"><strong>cold email outreach strategy</strong></span> that converts.</p>',
    },
    {
      icon:<FaRegImages />,
      title: "Campaign Management",
      desc: '<p>There is a science behind developing a lead generation funnel, and our team of experts has the secret recipe. Let them manage your <span style="color: #000080;"><strong>outbound sales campaigns</strong></span> while you focus on other opportunities.</p>',
    },
    {
      icon:<FaSearchPlus />,
      title: "Qualified Leads",
      desc: '<p>Save time, revenue and resources by delegating <span style="color: #000080;"><strong>cold outreach</strong></span> and <strong><span style="color: #000080;">lead qualification</span></strong> to our team. We’ll only pass your sales leaders the opportunities ready to schedule a meeting or demo.</p>',
    },
    {
      icon:<HiOutlineIdentification />,
      title: "B2B Company Database",
      desc: '<p><span data-preserver-spaces="true">Identifying the likely account for future business is crucial. We’re the <span style="color: #000080;"><strong>b2b</strong></span> <strong><span style="color: #000080;"><a href="https://en.wikipedia.org/wiki/Lead_generation" target="_blank" rel="noopener noreferrer" style="color: #000080;" tabindex="-1">lead generation</a></span>&nbsp;</strong>agency to get you the most accurate <span style="color: #000080;"><strong>company list</strong></span> within your targeted audience.&nbsp;<br></span></p>',
    },
    {
      icon:<FaCalendarAlt />,
      title: "Appointment Setting",
      desc: '<p>What’s better than a <span style="color: #000080;"><strong>list of qualified leads</strong></span>? How about a list of meetings on your calendar? Let our team of sales professionals deliver you <span style="color: #000080;"><strong>warm sales leads</strong></span> who are ready to learn more about your product or service.</p>',
    },
  ];
  return (
    <section className="bg-white w-[80%] ml-[10%] pb-16">
      <div className="flex justify-center w-full mt-16">
        <h2 className="text-[#980071] text-[45px] leading-[1.2em] text-center font-bold pb-5">
          How We Can Partner
        </h2>
      </div>
      <div className="flex flex-row  w-full flex-wrap justify-between gap-y-20 mt-20">
        {data.map((item) => (
          <div data-aos="zoom-in" className="flex flex-col space-y-4 w-full sm:w-[45%] md:w-[22%] justify-between items-center text-center py-7 px-3 rounded-xl shadow-[0px_2px_70px_0px] shadow-[#6E82D02E]">
            <div className="p-5 text-4xl rounded-full text-[#330779] bg-[#f7828e]">
              {item.icon}
            </div>
            <h3 className="text-lg text-[#330779]"> {item.title} </h3>
            <div
              dangerouslySetInnerHTML={{ __html: item.desc }}
              className="text-sm leading-6 text-[#666]"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Partner;
