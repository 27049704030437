import React from "react";
import { FaCalendarAlt, FaLinkedinIn, FaSearchPlus } from "react-icons/fa";
import { HiMiniPresentationChartBar } from "react-icons/hi2";
import { IoIosMailOpen } from "react-icons/io";
import { IoMailSharp } from "react-icons/io5";
import { RiPuzzle2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

const Works = () => {
  return (
    <section className="w-full my-20">
      <div className="w-full flex flex-col md:flex-row  justify-evenly items-center">
        <h2 className="text-[#330779] text-4xl md:text-5xl mb-10 md:mb-0">What we do best</h2>
        <Link
          to={"/contact-us"}
          className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]"
        >
          <b className="uppercase">Talk to specialist</b>
        </Link>
      </div>
      <div className="w-[80%] mx-auto my-10 flex flex-col md:flex-row space-y-16 md:space-x-16">
        <div className="flex flex-col gap-5 justify-evenly w-full md:w-1/4">
          <div className="flex space-x-4 items-center">
            <RiPuzzle2Fill className="p-2 bg-yellow-200 text-[#330779] rounded-full text-4xl" />
            <h4 className="text-[#980071]"> B2B Lead Generation</h4>
          </div>
          <div className="flex space-x-4 items-center">
            <FaLinkedinIn className="p-2 bg-yellow-200 text-[#330779] rounded-full text-4xl" />
            <h4 className="text-[#980071]"> LinkedIn Marketing</h4>
          </div>
          <div className="flex space-x-4 items-center">
            <FaCalendarAlt className="p-2 bg-yellow-200 text-[#330779] rounded-full text-4xl" />
            <h4 className="text-[#980071]"> Appointment Setting</h4>
          </div>
          <div className="flex space-x-4 items-center">
            <FaLinkedinIn className="p-2 bg-yellow-200 text-[#330779] rounded-full text-4xl" />
            <h4 className="text-[#980071]"> LinkedIn Lead Generation</h4>
          </div>
          <div className="flex space-x-4 items-center">
            <IoIosMailOpen className="p-2 bg-yellow-200 text-[#330779] rounded-full text-4xl" />
            <h4 className="text-[#980071]"> Cold Email Outreach</h4>
          </div>
          <div className="flex space-x-4 items-center">
            <FaSearchPlus className="p-2 bg-yellow-200 text-[#330779] rounded-full text-4xl" />
            <h4 className="text-[#980071]"> Sniper-Focused Prospecting</h4>
          </div>
          <div className="flex space-x-4 items-center">
            <IoMailSharp className="p-2 bg-yellow-200 text-[#330779] rounded-full text-4xl" />
            <h4 className="text-[#980071]"> Campaign Set up & Management</h4>
          </div>
          <div className="flex space-x-4 items-center">
            <HiMiniPresentationChartBar className="p-2 bg-yellow-200 text-[#330779] rounded-full text-4xl" />
            <h4 className="text-[#980071]"> Demand Generation</h4>
          </div>
        </div>
        <div className="w-full md:w-3/4 shadow-2xl rounded-xl">
          <img src="/img/about/best.png" alt="best" className="w-full" />
        </div>
      </div>
    </section>
  );
};

export default Works;
