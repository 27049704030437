import React, { useEffect } from 'react'
import Hero from './Hero'
import Setup from './Setup'
import Quality from './Quality'
import Details from './Details'
import Clients from '../Landing/Clients'
import HomeFooter from '../Footer/HomeFooter'

const HomePage = () => {
  useEffect(() => {
    document.title = "Lead Generation Machine - EmailMiners - Qualified B2B Leads";  
  }, []);
  return (
    <>
    <Hero />
    <Setup />
    <Quality />
    <Details />
    <Clients />
    <HomeFooter/>
    </>
  )
}

export default HomePage