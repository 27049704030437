import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="bg-gradient-to-b from-[#330779] to-[#980071]">
      <div
        style={{ backgroundImage: `url("/img/home/hero.png")` }}
        className="bg-no-repeat bg-cover"
      >
        <div className="flex flex-col items-center justify-evenly min-h-screen text-center gap-8 text-white pt-20 w-[80%] max-w-[1080px] mx-auto">
          <div>
            <h4 className="mb-5 md:tracking-[0.6em]">
              ON A MISSION TO GROW YOUR BUSINESS
            </h4>
            <h1 className="text-5xl md:text-8xl mb-5">About Us</h1>
          </div>
          <div className="mx-auto md:w-[70%]">
            <p className="text-xl mb-8">
              <span style={{ fontWeight: "400" }}>
                Have you ever worked with a company that felt so profoundly that
                they were meant to be a part of your business’ growth journey?{" "}
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{ fontWeight: "400" }}>
                At{" "}
                <span style={{ color: "#ffff99" }}>
                  <strong>EmailMiners</strong>
                </span>
                , we hold these values true, no matter the client. We want to be
                a partner in writing your business success story—collaboration
                always comes first for us.
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{ fontWeight: "400" }}>
                Since 2017, we’ve been working to rewrite the narrative on{" "}
                <span style={{ color: "#ffff99" }}>
                  <strong>B2B lead generation</strong>
                </span>
                support services. So often, companies in our industry seem only
                to work to benefit themselves. They simply repeatedly re-sell
                the same
                <span style={{ color: "#ffff99" }}>
                  <strong>prospecting lists</strong>
                </span>
                , which doesn’t improve your chances of making a sale more than
                the competition.
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{ fontWeight: "400" }}>
                But, at{" "}
                <span style={{ color: "#ffff99" }}>
                  <strong>EmailMiners</strong>
                </span>
                , we do it differently.
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{ fontWeight: "400" }}>
                We are a small business that understands the challenges of other
                small businesses, and we’ve built our products and services to
                add as much value as possible for our clients.
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{ fontWeight: "400" }}>
                We understand the fate of our clients might rely on their
                ability to close the deal, so we view the importance of{" "}
                <span style={{ color: "#ffff99" }}>
                  <strong>high-quality leads</strong>
                </span>
                as a win-win for both of us.
              </span>
            </p>
          </div>
          <Link
            to={"/contact-us"}
            className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]"
          >
            <b className="uppercase">Talk to specialist</b>
          </Link>
        </div>
        <div
          style={{
            backgroundImage: "url('/image.svg')",
            backgroundSize: "100%",
          }}
          className="h-[100px] sm:h-[150px] md:h-[250px] z-10 scale-x-[-1]"
        ></div>
      </div>
    </section>
  );
};

export default Hero;
