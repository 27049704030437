import React from "react";
import { Link } from "react-router-dom";

const Experience = () => {
  return (
    <section className="bg-white my-24">
      <div className="w-[80%] mx-auto max-w-[1080px] text-center">
        <div className="mb-10 w-[80%] mx-auto">
          <h2 className="text-[#980071] md:text-5xl mb-5">Experience Pays Off</h2>
          <h3 className="text-lg md:text-[22px] font-[700] w-[90%] mx-auto mb-10">
            Our approach to{" "}
            <span style={{ color: "#000080" }}>Lead Qualification</span> is
            centered around{" "}
            <span style={{ color: "#000080" }}>
              Learning & Growing Together
            </span>
          </h3>
          <p className="mb-10">
            The{" "}
            <span className="text-[#000080]" >
              <strong>EmailMiners</strong>
            </span>{" "}
            team is led by a B2B sales professional and CEO with nearly a decade
            invested in honing lead generation skills and developing{" "}
            <span className="text-[#000080]">
              <strong>hyper-personalized targeting strategies</strong>
            </span>{" "}
            that work.
          </p>
          <p className="mb-20">
          In today’s modern sales world, the best successes will be achieved using these strategies, and years of trial and error have led us to the perfect balance of resourcefulness and skill for finding{" "}
            <span className="text-[#000080]" >
              <strong>high-quality leads.</strong>
            </span>
            
          </p>
        </div>
        <div>
          <h4 className="italic">
           
              <span style={{ color: "#800080" }}>
                EmailMiners is proud to have been recognized for our industry
                excellence.
              </span>
           
          </h4>
          <p>&nbsp;</p>
          <h4 className="mb-5">
            <span style={{ color: "#000080" }}>
              <Link
                to="https://www.prweb.com/releases/the_top_lead_generation_companies_in_january_according_to_designrush/prweb19107931.htm"
                target="_blank"
                rel="noopener"
                style={{ color: "#000080" }}
              >
                2023 Top Lead Generation Companies
              </Link>
            </span>
          </h4>
          <h4>
            <span>
              <strong></strong>
            </span>
          </h4>
          <h4>
            <span style={{ color: "#000080" }}>
              
                <Link
                  to="https://www.prweb.com/releases/the_top_digital_marketing_agencies_in_april_according_to_designrush/prweb19268675.htm"
                  target="_blank"
                  rel="noopener"
                  style={{ color: "#000080" }}
                >
                  2023 Top Digital Marketing Companies
                </Link>
                &nbsp;
             
            </span>
          </h4>
        </div>
      </div>
      <div className="flex justify-center w-[80%] m-auto max-w-[1080px] my-20  h-fit relative pt-[45%] md:pt-[37%] lg:pt-[33.2963%]">
        <iframe
          loading="lazy"
          title="EmailMiners - B2B Lead Generation Agency - B2B Qualified Leads -Appointment Setting- B2B Prospecting"
          src="https://www.youtube.com/embed/74NZhT7j7ac?feature=oembed"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen=""
          name="fitvid1"
          className="w-full h-full absolute top-0 left-0 right-0"
        ></iframe>
      </div>
    </section>
  );
};

export default Experience;
