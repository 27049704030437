import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="bg-gradient-to-b from-[#330779] to-[#980071]">
      <div
        style={{ backgroundImage: `url("/img/home/hero.png")` }}
        className="bg-no-repeat bg-cover"
      >
        <div className="flex flex-col items-center justify-evenly  text-center gap-8 text-white pt-32 w-[80%] max-w-[1080px] mx-auto">
          <div>
            <h3 className="mb-5 md:tracking-widest uppercase">
              Contact EmailMiners
            </h3>
            <h1 className="text-6xl md:text-7xl leading-[5rem] mb-5">Generate B2B Leads</h1>
          </div>
          <div className="mx-auto md:w-[70%]">
            <p className="text-xl mb-8">
              <span style={{ fontWeight: "400" }}>
                Do you want to generate more{" "}
                <span style={{ color: "#ffff99" }}>
                  <strong>B2B Leads</strong>
                </span>{" "}
                for your Sales Team?
              </span>
            </p>

            <p className="text-xl mb-8">
              <span style={{ fontWeight: "400" }}>
                We together will build a “
                <span style={{ color: "#ffff99" }}>
                  <strong>Lead Generation Machine</strong>
                </span>
                ” for you to generate{" "}
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{ fontWeight: "400" }}>
                <span style={{ color: "#ffff99" }}>
                  <strong>Qualified Leads</strong>
                </span>{" "}
                for your Sales Team to Close.
              </span>
            </p>
          </div>
          <Link to={"/contact-us"} className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">Speak to specialist</b>
          </Link>
        </div>
        <div
          style={{
            backgroundImage: "url('/image.svg')",
            backgroundSize: "100%",
          }}
          className="h-[100px] sm:h-[150px] md:h-[250px] z-10 scale-x-[-1]"
        ></div>
      </div>
    </section>
  );
};

export default Hero;
