import { useEffect, useState } from 'react';
import { IoIosArrowUp } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [pathname]);

  // Show button when page is scrolled down by 300px
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className='p-3 bg-gray-700 bg-opacity-55 bottom-20 rounded-md right-5 cursor-pointer fixed z-50 text-white'
        //   style={{
        //     position: 'fixed',
        //     bottom: '30px',
        //     right: '30px',
        //     padding: '10px 20px',
        //     fontSize: '16px',
        //     backgroundColor: '#007bff',
        //     color: '#fff',
        //     border: 'none',
        //     borderRadius: '5px',
        //     cursor: 'pointer',
        //   }}
        >
         <IoIosArrowUp />
        </button>
      )}
    </>
  );
};

export default ScrollToTop;
