import React, { useEffect } from 'react'
import Hero from './Hero'
import Goal from './Goal'
import Explore from './Explore'
import ServiceFooter from '../Footer/ServiceFooter'

const Services = () => {
  useEffect(() => {
    document.title = "B2B Lead Generation Services - EmailMiners- LinkedIn Lead Generation";  
  }, []);
  return (
    <>
    <Hero />
    <Goal />
    <Explore />
    <ServiceFooter />
    </>
  )
}

export default Services