import React from "react";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import Bottom from "./Bottom";
import Socials from "./Socials";

const AboutFooter = () => {
  return (
    <section className="bg-gradient-to-b from-[#330779] to-[#980071] text-white">
      <div
        style={{ backgroundImage: `url("/img/home/hero.png")` }}
        className="bg-no-repeat bg-cover pb-36"
      >
        <div
          style={{
            backgroundImage: "url('/image.svg')",
            backgroundSize: "100%",
            
          }}
          className="h-[100px] sm:h-[150px] md:h-[250px] z-10 scale-y-[-1]"
        ></div>
        <div className="w-full flex flex-col items-center md:px-[30%] text-center">
          <h3 className="text-base sm:text-3xl mb-5">Maximize Your ROI and Achieve Your Goals</h3>
          <h2 className="md:text-[3rem] leading-[1.5em] mb-10">
          Your B2B Lead Generation Strategy Starts Here
          </h2>
          <Link to={"/contact-us"} className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">Speak to specialist</b>
          </Link>
          <Socials />
        </div>
      </div>
      <Bottom />
    </section>
  );
};

export default AboutFooter;
