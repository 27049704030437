import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="bg-gradient-to-b from-[#330779] to-[#980071]">
      <div
        style={{ backgroundImage: `url("/img/home/hero.png")` }}
        className="bg-no-repeat bg-cover"
      >
        <div className="flex flex-col items-center justify-evenly min-h-screen text-center gap-8 text-white pt-20 w-[80%] max-w-[1080px] mx-auto">
          <div>
            <h3 className="mb-5 md:tracking-widest">
            The Ultimate Sales Solution For Small Businesses
            </h3>
            <h1 className="text-3xl md:text-6xl md:leading-[5rem] mb-5">B2B Lead Generation Services to Maximize Your Success</h1>
          </div>
          <div className="mx-auto md:w-[70%]" >
            <p className="text-xl mb-8">
              <span style={{fontWeight: "400"}}>
              We are a strategic{" "}
                <span style={{color: "#ffff99"}}>
                  <strong>B2B lead generation agency</strong>
                </span>
                offering a full suite of products and services designed to increase sales opportunities for your small business.
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{fontWeight: "400"}}>
              Every{" "}
                <span style={{color: "#ffff99"}}>
                  <strong>prospect list</strong>
                </span>
                we deliver and every appointment we schedule is completed in a hyper-personalized way to
                <span style={{color: "#ffff99"}}>
                  <strong>maximize your sales</strong>
                </span>
                success.
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{fontWeight: "400"}}>
              We are the ultimate sales solution for teams with limited sales resources and big goals.
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{fontWeight: "400"}}>
              We are a small business that understands the challenges of other small businesses, and we’ve built our products and services to add as much value as possible for our clients.
              </span>
            </p>
            <p className="text-xl mb-8">
              <span style={{fontWeight: "400"}}>
              Think of a “
                <span style={{color: "#ffff99"}}>
                  <strong>Lead Generation Machine</strong>
                </span>
                ” that will generate{" "}
                <span style={{color: "#ffff99"}}>
                  <strong>Qualified-Leads</strong>
                </span>
                for your Sales Team to close deals.
              </span>
            </p>
          </div>
          <Link to={"/contact-us"} className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">Speak to specialist</b>
          </Link>
          
          
          
        </div>
        <div
          style={{
            backgroundImage: "url('/image.svg')",
            backgroundSize: "100%",
          }}
          className="h-[100px] sm:h-[160px]md:h-[250px] z-10 scale-x-[1]"
        ></div>
      </div>
    </section>
  );
};

export default Hero;
