import { useEffect } from 'react';

const HubSpotScript = () => {
  useEffect(() => {
    const script2 = document.createElement('script');
    script2.src = "//js.hs-scripts.com/39982851.js";
    script2.async = true;
    script2.defer = true;
    document.body.appendChild(script2);

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script2);
    };
  }, []);

  return null; // No UI component needed
};

export default HubSpotScript;
