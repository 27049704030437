import React from "react";

const YouTube = () => {
  return (
    <section className="bg-gradient-to-b z-[2] from-white via-[#F2F2FA] to-[#F2F2FA] relative">
      <div className="flex justify-center w-[80%] m-auto max-w-[1080px] my-36  h-fit relative pt-[45%] md:pt-[38.2963%] lg:pt-[31.2963%]">
        <iframe
          loading="lazy"
          title="EmailMiners - B2B Lead Generation Agency - B2B Qualified Leads -Appointment Setting- B2B Prospecting"
          src="https://www.youtube.com/embed/74NZhT7j7ac?feature=oembed"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen=""
          name="fitvid1"
          className="w-full h-full absolute top-0 left-0 rounded-3xl"
        ></iframe>
      </div>
      <div
        style={{
          backgroundImage: "url('/image.svg')",
          backgroundSize: "100% 250px",
        }}
        className="h-[250px] z-[-1] scale-x-[-1] bottom-0 absolute left-0 right-0"
      ></div>
    </section>
  );
};

export default YouTube;
