import React from "react";
import { Link } from "react-router-dom";

 const WhyChoose = () => {
  return (
    <section className="bg-white w-[70%] ml-[15%] pb-16">
      <div className="flex  justify-center w-full mt-16">
        <h2 className="text-[#980071] text-[45px] leading-[1.2em] text-center font-bold pb-5">
          Best Lead Generation Companies
        </h2>
      </div>
      <div className="flex flex-col md:flex-row w-full mt-24 ">
        <div className="w-full md:w-[45%]">
          <div className="flex flex-col items-center text-center mb-8">
            <h2 className="text-[#980071] text-[45px] leading-[1.2em] font-bold pb-5">
              Why Choose EmailMiners
            </h2>
            <p>
              As an experienced{" "}
              <strong>
                <span style={{ color: "#000080" }}>
                  team of lead generation experts
                </span>
              </strong>
              , we understand the best ways to help your growing business. We’ve
              been recognized as a{" "}
              <strong>
                <a
                  href="https://www.prweb.com/releases/the_top_lead_generation_companies_in_january_according_to_designrush/prweb19107931.htm"
                  tabindex="-1"
                >
                  Top Lead Generation Company
                </a>
              </strong>{" "}
              in 2023. Our{" "}
              <span style={{ color: "#000080" }}>
                <strong>hyper-personalized prospecting</strong>
              </span>{" "}
              method is unique to our industry. If you’ve tried other
              prospecting methods in the past and weren’t successful, it’s time
              to give{" "}
              <strong>
                <span style={{ color: "#000080" }}>EmailMiners</span>
              </strong>{" "}
              a try.
            </p>
          </div>
          <div className="mb-8">
            <p style={{textAlign: "left;"}}>
              <strong>
                <span style={{color: "#000080"}}>
                  We’re different in the industry because:
                </span>
              </strong>
            </p>
            <ul className="list-disc">
              <li style={{textAlign: "left;"}}>
                We understand the value of every lead to your business
              </li>
              <li style={{textAlign: "left;"}}>
                We take pride in helping you grow your business
              </li>
              <li style={{textAlign: "left;"}}>
                We work in collaboration toward your goals — not ours
              </li>
            </ul>
          </div>
          <Link to={"/about-emailminers"} className="py-5 text-white px-7 shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[8px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[3px]">
            <b className="uppercase">Learn MORE</b>
          </Link>
        </div>
        <div data-aos="fade-up" className=" w-full md:w-[55%] relative mt-16 md:mt-0">
          <img src="/img/home/third.png" alt="second" />
        </div>
      </div>
    </section>
  );
};

export default WhyChoose;
