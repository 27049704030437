import React, { useEffect } from 'react'
import Hero from './Hero'
import HomeAbout from './HomeAbout'
import Partner from './Partner'
import WhyChoose from './WhyChoose'
import Video from './Video'
import Clients from './Clients'
import Footer from '../Footer/Footer'
import YouTube from './YouTube'
import Achievements from './Achievements'

const Home = () => {
  useEffect(() => {
    document.title = "B2B Lead Generation Agency - EmailMiners - B2B Appointment Setting";  
  }, []);
  return (
    <section className=' w-full'>
        <Hero />
        <HomeAbout />
        <Partner />
        <WhyChoose />
        <Video/>
        <YouTube/>
        <Clients />
        <Achievements />
        <Footer />
    </section>
  )
}

export default Home