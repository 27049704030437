import React, { useEffect } from 'react'
import Hero from './Hero'
import Experience from './Experience'
import Leaders from './Leaders'
import Values from './Values'
import Works from './Works'
import AboutFooter from '../Footer/AboutFooter'

const About = () => {
  useEffect(() => {
    document.title = "Qualified Lead Generation - About EmailMiners Sales Automation";  
  }, []);
  return (
    <>
    <Hero />
    <Experience />
    <Leaders />
    <Values />
    <Works />
    <AboutFooter />
    </>
  )
}

export default About