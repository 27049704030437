import React from "react";
import { AiFillChrome } from "react-icons/ai";

const Clients = () => {
  const data = [
    {
      name: "Jack Lee",
      desc: "I could do it better in-house, but EmailMiners has helped show me the value of leveraging an outsourced sales partner. I should have hired them soon to have great results. Will definitely recommend EmailMiners for lead generation. ",
      occupation:"Head of Marketing, Space Digital Media",
      img:"img/Client/First.jpg"
    },
    {
        name: "Debra Becker",
        desc: "EmailMiners has been a real game-changer for our sales team. In 3 months, they delivered over 38 leads, leading to several high-quality meetings. I highly recommend them for their quality, flexibility, and robust results. ",
        occupation:"Founder & CEO, Debra Coaching",
        img:"img/Client/debra.jpg"
      },
      {
        name: "Steven Latasa-Nicks",
        desc: "I have worked with EmailMiners on multiple occasions, once here at Stellar Menus and once at SlideShop (now kroma.ai). On both occasions, I have been very pleased with the results. I find the team effective, friendly and reliable.",
        occupation:"CEO, Stellar Menus",
        img:"img/Client/steven.jpg"
      },
      {
        name: "John Wise",
        desc: "We expect to close multiple sales in the next month-any one of which would represent well over 15x ROI on our investment with EmailMiners. Highly recommend them to businesses looking for a game-changing boost to their sales pipeline.",
        occupation:"Head of Sales, DataLead",
        img:"img/Client/john.jpg"
      },
  ];

  return (
    <section className="bg-gradient-to-b from-white via-[#F2F2FA] to-[#F2F2FA] pb-20">
        <div className="w-[80%] ml-[10%]"> 
      <div className="flex flex-wrap justify-center w-full mt-16">
        <h2 className="text-[#980071] md:text-[45px] leading-[1.2em] text-center font-bold pb-5">
        Our Clients See The Value
        </h2>
      </div>
      <div className="flex flex-row  w-full flex-wrap justify-between gap-y-20 mt-20">
        {data.map((item) => (
          <div className="flex w-full sm:w-[45%] md:w-[22%] flex-col p-7 items-center text-center rounded-xl gap-4 shadow-[0px_2px_70px_0px] shadow-[#6E82D02E]">
            <img src={item.img} className="w-[100px] h-[100px] rounded-full"/>
            <p className="text-base leading-7 text-left text-[#666]">{item.desc} </p>
            <div className="text-left w-full leading-5">

            <p className="text-[#666]">
              <strong>{item.name}</strong>
            </p>
            <p className="text-[#666]">{item.occupation}</p>
            </div>
          </div>
        ))}
      </div>
      </div>
    </section>
  );
};

export default Clients;
