import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <section className="bg-gradient-to-b from-[#330779] to-[#980071] ">
        <div
          style={{ backgroundImage: `url("/img/home/hero.png")` }}
          className="bg-no-repeat bg-inherit"
        >
          <div className="flex flex-col items-center justify-evenly min-h-screen text-center gap-8 text-white pt-20 w-[80%] mx-auto max-w-[1080px] bg-no-repeat bg-cover">
            <div>
              <h4 className="mb-5 tracking-widest">
                Grow Your Business With Qualified B2B Leads
              </h4>
              <h1 className="text-5xl md:text-7xl mb-5">
                Hyper-Personalized Targeting To Drive More Sales
              </h1>
            </div>
            <div className="md:px-36">
              <p>
                At{" "}
                <span style={{ textDecoration: "underline" }}>
                  <strong>EmailMiners</strong>
                </span>
                , we design and strategize a “
                <a href="https://emailminers.com/lead-generation-machine/">
                  <span style={{ color: "#ffff99" }}>
                    <strong>Lead Generation Machine</strong>
                  </span>
                </a>
                ” to turn your <strong>desired prospects</strong> into the{" "}
                <strong>most valuable customers</strong>.
              </p>
              <p>
                <strong>
                  You will get your calendar filled with qualified leads.
                </strong>
              </p>
              <p>
                We leverage the{" "}
                <span style={{ color: "#ffff99" }}>
                  <strong>LinkedIn Lead Generation System</strong>
                </span>
                &nbsp;
                <strong>
                  <span style={{ color: "#ffff99" }}>
                    Sniper Targeted Prospecting
                  </span>
                  <br />
                </strong>{" "}
                &amp;
                <br />
                <span style={{ color: "#ffff99" }}>
                  <strong>Cold Email Machine</strong>
                </span>
                .
              </p>
            </div>
            <Link
              to={"/contact-us"}
              className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]"
            >
              <b className="uppercase">Speak to specialist</b>
            </Link>
          </div>
          <div
            style={{
              backgroundImage: "url('/image.svg')",
              backgroundSize: "100%",
            }}
            className="h-[100px] sm:h-[150px] md:h-[250px] z-10 scale-x-[-1]"
          ></div>
        </div>
      </section>
      <section className="bg-white my-24">
        <div className="w-[80%] mx-auto max-w-[1080px] text-center">
          <div className="mb-10">
            <h3>
              <span style={{ color: "#000080" }}>
                With EmailMiners, your business will:
              </span>
            </h3>
            <p>&nbsp;</p>
            <p>
              <span>✔ </span>
              <span>Save valuable time and resources prospecting</span>
            </p>
            <p>
              <span>✔ </span>
              <span>
                Receive a list of warm leads pre-qualified for your product or
                service
              </span>
            </p>
            <p>
              <span>✔ </span>
              <span>Have the support of an experienced sales team</span>
            </p>
            <p>
              <span>✔ </span>
              <span>
                Improve ROI for your business with a higher close percentage
              </span>
            </p>
          </div>
          <div className="mb-10">
            <p>
              We know your time and resources are limited—there aren’t enough
              hours in the day to reach everyone on your sales list. So let us
              deliver you a list of{" "}
              <strong>
                <span style={{ color: "#000080" }}>high-quality prospects</span>
              </strong>{" "}
              ripe for sale.
            </p>
            <p>
              With our{" "}
              <span style={{ color: "#000080" }}>
                <strong>hyper-personalized targeting strategies</strong>
              </span>
              , we’ll help you maximize the efficiency of a small sales team to{" "}
              <span style={{ color: "#000080" }}>
                <strong>grow your business beyond expectation</strong>
              </span>
              .
            </p>
          </div>
          <div className="flex w-full justify-center">
            <Link
              to={"/contact-us"}
              className="py-5 mb-10 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]"
            >
              <b className="uppercase">Speak to specialist</b>
            </Link>
          </div>
          <div className="mt-20">
            <h4>
              <strong>
                <span style={{ color: "#800080" }}>
                  EmailMiners is proud to have been recognized for our industry
                  excellence.
                </span>
              </strong>
            </h4>
            <p>&nbsp;</p>
            <h4>
              <span style={{ color: "#000080" }}>
                <a
                  href="https://www.prweb.com/releases/the_top_lead_generation_companies_in_january_according_to_designrush/prweb19107931.htm"
                  target="_blank"
                  rel="noopener"
                  style={{ color: "#000080" }}
                >
                  <strong>2023 Top Lead Generation Companies</strong>
                </a>
              </span>
            </h4>
            <h4>
              <span>
                <strong></strong>
              </span>
            </h4>
            <h4>
              <span style={{ color: "#000080" }}>
                <strong>
                  <a
                    href="https://www.prweb.com/releases/the_top_digital_marketing_agencies_in_april_according_to_designrush/prweb19268675.htm"
                    target="_blank"
                    rel="noopener"
                    style={{ color: "#000080" }}
                  >
                    2023 Top Digital Marketing Companies
                  </a>
                  &nbsp;
                </strong>
              </span>
            </h4>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
