import React from 'react'

const Goal = () => {
  return (
    <section className='w-full mt-20 pb-20 relative'>
        <div className='w-full text-center mb-10'>
            <h3 className='text-[#330779] md:text-5xl'>But First, Your Goals</h3>
        </div>
        <div className='w-[70%] mx-auto text-center'>
            <p className='mb-5'>No matter which services you choose, we always start with your goals first.</p>
            <p className='mb-5'> How can we help you achieve them?</p>
            <p className='mb-5'>Our client-first values enable us to elevate services with the flexibility needed to fit your needs.</p>
            <p className='mb-5'>Have a question about a service? <span className='text-[#000080]'> <strong>Reach out.</strong> </span></p>
        </div>
        <div className='w-full flex justify-center '>
            <img src='/img/services/team.png' className='shadow-2xl rounded-xl' alt='Linkedin_lead_generation_funnel_emailminers'/>
        </div>
        <div
          style={{
            backgroundImage: "url('/img/services/wave.svg')",
            backgroundSize: "100% 600px",
          }}
          className="h-[600px] z-[-1] absolute bottom-0 left-0 right-0 "
        ></div>
    </section>
  )
}

export default Goal