import React from 'react'
import { BsTwitterX } from 'react-icons/bs'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Socials = () => {
  return (
    <div className="flex justify-center space-x-4 text-xl text-slate-800">
            <Link
              target="_blank"
              to={"https://www.facebook.com/EmailMiners/"}
              className="bg-yellow-500 rounded-[50%] p-2"
            >
              <FaFacebookF />
            </Link>
            <Link
              target="_blank"
              to={"https://x.com/email_miners"}
              className="bg-yellow-500 rounded-[50%] p-2"
            >
              <BsTwitterX />
            </Link>
            <Link
              target="_blank"
              to={"https://in.linkedin.com/company/emailminers"}
              className="bg-yellow-500 rounded-[50%] p-2"
            >
              <FaLinkedinIn />
            </Link>
            <Link
              target="_blank"
              to={"https://www.instagram.com/email_miners/"}
              className="bg-yellow-500 rounded-[50%] p-2"
            >
              <FaInstagram />
            </Link>
            <Link
              target="_blank"
              to={"https://www.youtube.com/channel/UCzh_7cSoqEh2N3LghbaZauQ"}
              className="bg-yellow-500 rounded-[50%] p-2"
            >
              <FaYoutube />
            </Link>
          </div>
  )
}

export default Socials