import React, { useEffect } from "react";
import { IoHome, IoLocationSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import HubSpotScript from "./Hubspot";

const Information = () => {
  useEffect(() => {
    // Calendly widget script
    const calendlyScript = document.createElement("script");
    calendlyScript.src = "https://assets.calendly.com/assets/external/widget.js";
    calendlyScript.async = true;
    document.body.appendChild(calendlyScript);

    // HubSpot widget script
    const hubspotFormScript = document.createElement("script");
    hubspotFormScript.src = "//js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(hubspotFormScript);
    hubspotFormScript.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
          // @TS-ignore
          window.hbspt.forms.create({
              portalId: '39982851',
              formId: '5a2ed076-55a1-45c0-bd3f-7d91c913d366',
              target: '#hubspotForm'
          })
      }
  });

    // Cleanup when the component is unmounted
    return () => {
      document.body.removeChild(calendlyScript);
      document.body.removeChild(hubspotFormScript);
    };
  }, []);
  return (
    <section className="w-full">
      <div className="flex flex-col md:flex-row gap-4 w-[80%] mx-auto justify-between my-24">
        <div className="flex flex-col space-y-4 md:w-[30%] justify-around items-center text-center py-10 px-14 rounded-xl shadow-[0px_2px_70px_0px] shadow-[#6E82D02E]">
          <div className="p-5 text-4xl rounded-full text-[#330779] bg-[#f7828e]">
            <IoLocationSharp />
          </div>
          <h3 className="text-lg text-[#330779]"> Office Address </h3>
          <p className="text-sm">
            {" "}
            Dreams Elegance, Pune, Maharashtra, India, 411028{" "}
          </p>
        </div>
        <div className="flex flex-col space-y-4 md:w-[30%] justify-around items-center text-center py-7 px-3 rounded-xl shadow-[0px_2px_70px_0px] shadow-[#6E82D02E]">
          <div className="p-5 text-4xl rounded-full text-[#330779] bg-[#f7828e]">
            <MdEmail />
          </div>
          <h3 className="text-lg text-[#330779]"> Email Us </h3>
          <p className="text-sm font-bold"> bonjour@emailminers.com </p>
        </div>
        <div className="flex flex-col space-y-4 md:w-[30%] justify-around items-center text-center py-7 px-3 rounded-xl shadow-[0px_2px_70px_0px] shadow-[#6E82D02E]">
          <div className="p-5 text-4xl rounded-full text-[#330779] bg-[#f7828e]">
            <IoHome />
          </div>
          <h3 className="text-lg text-[#330779]"> Hours of Operation (IST) </h3>
          <div>
            <p className="text-sm font-bold mb-2"> Monday to Friday</p>
            <p className="font-bold text-sm mb-2">
              <span className="text-[#000080]">Morning -</span> 11:00 am – 4:00
              pm{" "}
            </p>
            <p className="font-bold text-sm">
              <span className="text-[#000080]">Evening -</span> 7:00 pm – 3:00
              am{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-x-10 md:flex-row w-[80%] mx-auto justify-between mt-44 mb-36 ">
        {/* <div className="md:w-1/2 text-center">
          <h3 className="text-3xl md:text-5xl text-[#333] mb-5">Send a Message</h3>
          <p className="text-base mb-5">
            Are you interested in driving growth? We’re just an email away.
          </p>
          <div className="w-full flex flex-col space-y-4">
            <div className="flex w-full space-x-3">
              <input
                type="text"
                placeholder="Name"
                className="w-1/2 focus:outline-none border-b-[3px]  bg-[#6e82d00a] border-solid border-[#bec2d8] p-4 rounded-lg"
              />
              <input
                type="text"
                placeholder="Email Address"
                className="w-1/2 focus:outline-none border-b-[3px]  bg-[#6e82d00a] border-solid border-[#bec2d8] p-4 rounded-lg"
              />
            </div>
            <div className="w-full">
              <textarea
                placeholder="Message"
                className="w-full h-48 focus:outline-none border-b-[3px]  bg-[#6e82d00a] border-solid border-[#bec2d8] p-4 rounded-lg"
              />
            </div>
            <div className="w-full flex justify-end items-center space-x-4">
              <p className="text-[#666]">12+15 = </p>
              <input
                type="number"
                className="w-14 no-arrows focus:outline-none border-b-[3px]  bg-[#6e82d00a] border-solid border-[#bec2d8] p-4 rounded-lg"
              />
              <div className="py-4 px-7  text-white bg-[#330779] rounded-md tracking-[5px]">
                <Link>
                  <b className="uppercase">Lets go</b>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div id="hubspotForm" className="md:w-1/2 min-h-fit" ></div>
        <div className="md:w-1/2">
          <div
            className="calendly-inline-widget h-[1170px] md:h-full"
            data-url="https://calendly.com/emailminers07/30min"
            
          />
        </div>
      </div>
      <HubSpotScript/>
    </section>
  );
};

export default Information;
